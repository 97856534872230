export var LightenDarkenColor = function (hex, lum) {
  if (!hex) {
    return hex;
  }
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;
  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }
  return rgb;
};
export var getQueryVariable = function (queryString, variable) {
  var query = queryString.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};
export var checkValue = function (value, isCheckLength) {
  if (isCheckLength === void 0) {
    isCheckLength = false;
  }
  var returnVal = false;
  if (value && value !== undefined && value !== null && value !== '') {
    if (isCheckLength) {
      if (value.length > 0) {
        returnVal = true;
      }
    } else {
      returnVal = true;
    }
  }
  return returnVal;
};