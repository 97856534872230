export var config = {
  bexApiUrl: 'https://weaver-loom-bex.rcp.partnerexperiences.test.exp-aws.net/graphql',
  caps_api: 'https://uk.hotels.com/capsapi/template-cplite/goguides/',
  ga_account: 'UA-167763302-1',
  media_store_url: '/media-studio/{page_name}',
  meso_concierge: {
    host: 'https://www.expedia.com/capsapi/template-cplite/',
    ga: 'UA-167763302-1',
    api: '/capsapi/',
    urlPattern: '/prepare/{feedName}',
    localStorageKey: 'concierge_wish_list'
  },
  price_format: {
    en_us: '${}',
    en_gb: '£{}',
    it_it: '€ {}',
    de_de: '{} €',
    fr_fr: '{} €',
    en_ca: 'CA ${}',
    fr_ca: '{} $ CA'
  },
  captureSiteKey: '6LcrEbIbAAAAAFKXvcCCyynEThhhJI313TbXNnYz',
  campaignTemplates: [{
    campaign: 'spotlight',
    templatesPriFix: ['articleTemplate', 'galleryTemplate', 'itineraryTemplate', 'listicleTemplate']
  }, {
    campaign: 'cplite',
    templatesPriFix: ['homepage']
  }, {
    campaign: 'concierge',
    templatesPriFix: ['conciergeHome']
  }, {
    campaign: 'mirror',
    templatesPriFix: ['mirrorSocialTemplate']
  }],
  vrboHotelSearchUrl: 'https://www.vrbo.com/serp/g',
  moduleHeadingSizes: [{
    type: "default",
    fontSize: null,
    lineHeight: null,
    fontSizeMobile: null,
    lineHeightMobile: null
  }, {
    type: "large",
    fontSize: 90,
    lineHeight: 1,
    fontSizeMobile: 50,
    lineHeightMobile: 1
  }, {
    type: "medium",
    fontSize: 42,
    lineHeight: 56,
    fontSizeMobile: 40,
    lineHeightMobile: 48
  }]
};