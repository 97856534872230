import { ContextStore } from 'bernie-plugin-mobx';
import { NOOP_LOGGER } from 'bernie-logger';
import { GoGuidesStore } from "./goguides-store";
import { GoGuidesSource } from '../sources';
export { GoGuidesStore };
export var stores = {
  context: function () {
    return new ContextStore(null, NOOP_LOGGER);
  },
  goGuidesStore: function () {
    return new GoGuidesStore(new GoGuidesSource(), {}, NOOP_LOGGER);
  }
};