import { checkValue } from "components/common/helper";
export var getMoreTextIndicator = function (locale) {
  return "...";
};
export var cleanseText = function (html) {
  var stripHtml;
  try {
    stripHtml = html.toString();
    stripHtml = stripHtml.replace(/(<([^>]+)>)/ig, '');
  } catch (e) {
    stripHtml = "";
  }
  return stripHtml;
};
export var getWordLocale = function (cleanText, locale) {
  var words;
  try {
    if (["ja_JP", "zh_HK", "zh_TW"].includes(locale)) {
      words = Object.assign([], cleanText);
    } else {
      words = cleanText.split(" ");
    }
  } catch (e) {
    words = [];
  }
  return words;
};
export var wordCount = function (cleanText, locale) {
  var words = getWordLocale(cleanText, locale);
  return words.length;
};
export var generate = function (cleanText, locale, maxWords) {
  var result;
  try {
    var words = getWordLocale(cleanText, locale);
    var snippets = void 0;
    snippets = words.slice(0, maxWords);
    var stringJoin = " ";
    if (["ja_JP", "zh_HK", "zh_TW"].includes(locale)) {
      stringJoin = "";
    }
    result = snippets.join(stringJoin);
  } catch (e) {
    result = "";
  }
  return result;
};
export var generateSnippet = function (html, maxWords, locale) {
  var moreTextIndicator = getMoreTextIndicator(locale);
  if (!checkValue(html)) return html;
  var cleanText = cleanseText(html);
  var result;
  if (wordCount(cleanText, locale) <= maxWords) {
    result = cleanText;
  } else {
    result = generate(cleanText, locale, maxWords) + moreTextIndicator;
  }
  return result;
};
export var maxWord = {
  destinationHub: 70,
  categoryHub: 70,
  content: 70
};
export var getMaxWord = function (condition) {
  return maxWord[condition];
};
export var generateSnippetCategoryHub = function (text, locale) {
  if (locale === void 0) {
    locale = "en_GB";
  }
  return generateSnippet(text, getMaxWord("categoryHub"), locale);
};
export var getWordCountWithNumberOfCardsOnDhub = function (numberOfCards, cardIndex, locale) {
  var wordCount;
  switch (numberOfCards) {
    case 1:
      wordCount = 60;
      break;
    case 2:
    case 4:
      wordCount = 40;
      break;
    case 3:
      if (cardIndex == 1) {
        wordCount = 40;
      } else {
        wordCount = 20;
      }
      break;
    case 5:
    default:
      if (cardIndex < 3) {
        wordCount = 40;
      } else {
        wordCount = 20;
      }
      break;
  }
  wordCount = addExtraWordCount(wordCount, locale);
  return wordCount;
};
export var addExtraWordCount = function (wordCount, locale) {
  if (["ja_JP", "zh_HK", "zh_TW"].includes(locale)) {
    switch (locale) {
      case "ja_JP":
        wordCount = wordCount >= 40 ? wordCount + 48 : wordCount + 21;
        break;
      case "zh_HK":
        wordCount = wordCount + 20;
        break;
      case "zh_TW":
        wordCount = wordCount >= 40 ? wordCount + 40 : wordCount + 24;
        break;
    }
  }
  return wordCount;
};
export var getWordCountWithNumberOfCardsOnDhubCountryLevel = function (numberOfCards, cardIndex, descriptionLength, locale) {
  var wordCount;
  switch (numberOfCards) {
    case 1:
      wordCount = 60;
      break;
    case 2:
      // if (cardIndex == 1 || descriptionLength > 0) {
      //   wordCount = 60;
      // } else {
      //   wordCount = 0;
      // }
      wordCount = 60;
      break;
    case 3:
      if (cardIndex == 1) {
        wordCount = 60;
      } else {
        wordCount = 40;
      }
      break;
    case 4:
    default:
      if (cardIndex == 1) {
        wordCount = 60;
      } else {
        wordCount = 20;
      }
      break;
  }
  wordCount = addExtraWordCount(wordCount, locale);
  return wordCount;
};
export var generateSnippetDestinationHub = function (text, IsCountryLevelPage, numberOfCards, cardIndex, locale) {
  if (locale === void 0) {
    locale = "en_GB";
  }
  var requiredWordCount;
  var result;
  if (IsCountryLevelPage) {
    requiredWordCount = getWordCountWithNumberOfCardsOnDhubCountryLevel(numberOfCards, cardIndex, text.length, locale);
    result = generateSnippet(text, requiredWordCount, locale);
  } else {
    requiredWordCount = getWordCountWithNumberOfCardsOnDhub(numberOfCards, cardIndex, locale);
    result = generateSnippet(text, requiredWordCount, locale);
  }
  return result;
};
/*
trendingNow in home and dHub nHood level
catHub
mainHighlight in dhub except nHood level
*/
export var generateSnippetContent = function (text, numberOfCards, cardIndex, locale) {
  if (locale === void 0) {
    locale = "en_GB";
  }
  var result = generateSnippet(text, getWordCountWithNumberOfCardsForContent(numberOfCards, cardIndex), locale);
  return result;
};
export var getWordCountWithNumberOfCardsForContent = function (numberOfCards, cardIndex) {
  var wordCount;
  switch (numberOfCards) {
    case 1:
      wordCount = 60;
      break;
    case 2:
    case 4:
      wordCount = 40;
      break;
    case 3:
      wordCount = cardIndex == 1 ? 40 : 20;
      break;
    case 5:
      wordCount = cardIndex < 3 ? 40 : 20;
      break;
    default:
      // greater than 5 cards
      wordCount = cardIndex < 2 ? 40 : 20;
  }
  return wordCount;
};
/*
catHighlight in dhub except nHood level
*/
export var generateSnippetContentHighlight = function (text, numberOfCards, cardIndex, locale) {
  if (locale === void 0) {
    locale = "en_GB";
  }
  var wordCount = getWordCountWithNumberOfCardsForContent(numberOfCards, cardIndex);
  var result = generateSnippet(text, wordCount, locale);
  return result;
};