var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var clickStreamPageNameDetailed = function (contentTypeId) {
  switch (contentTypeId) {
    case 1:
      return 'Home Landing';
    case 2:
      return 'Destination Landing';
    case 3:
      return 'Category Landing';
    case 4:
      return 'Listicle';
    case 5:
      return 'Long form';
  }
};
export var clickStreamExperienceMap = function (pageUrlData) {
  return {
    page_name: 'Go Guides',
    page_name_detailed: clickStreamPageNameDetailed(pageUrlData.contentTypeId),
    app_name: 'go_guides',
    app_version: '1.0.0'
  };
};
export var domainDictionary = {
  en_GB: {
    domain: 'https://uk.hotels.com',
    queryString: 'pos=HCOM_UK&locale=en_GB',
    umbracoLocale: 'en-gb',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://uk.hotels.com'
  },
  en_US: {
    domain: 'https://www.hotels.com',
    queryString: 'pos=HCOM_US&locale=en_US',
    umbracoLocale: 'en-us',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://www.hotels.com'
  },
  fr_FR: {
    domain: 'https://fr.hotels.com',
    queryString: 'pos=HCOM_FR&locale=fr_FR',
    umbracoLocale: 'fr-fr',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://fr.hotels.com'
  },
  es_ES: {
    domain: 'https://es.hoteles.com',
    queryString: 'pos=HCOM_ES&locale=es_ES',
    umbracoLocale: 'es-es',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hoteles.com',
    xDefaultUmbracoLocale: 'es-mx',
    cannoical: 'https://es.hoteles.com'
  },
  it_IT: {
    domain: 'https://it.hotels.com',
    queryString: 'pos=HCOM_IT&locale=it_IT',
    umbracoLocale: 'it-it',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://it.hotels.com'
  },
  de_DE: {
    domain: 'https://de.hotels.com',
    queryString: 'pos=HCOM_DE&locale=de_DE',
    umbracoLocale: 'de-de',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://de.hotels.com'
  },
  ja_JP: {
    domain: 'https://jp.hotels.com',
    queryString: 'pos=HCOM_JP&locale=ja_JP',
    umbracoLocale: 'ja',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://jp.hotels.com'
  },
  ko_KR: {
    domain: 'https://kr.hotels.com',
    queryString: 'pos=HCOM_KR&locale=ko_KR',
    umbracoLocale: 'ko',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://kr.hotels.com'
  },
  zh_HK: {
    domain: 'https://zh.hotels.com',
    queryString: 'pos=HCOM_HK&locale=zh_HK',
    umbracoLocale: 'zh-hans-hk',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://zh.hotels.com'
  },
  zh_TW: {
    domain: 'https://tw.hotels.com',
    queryString: 'pos=HCOM_TW&locale=zh_TW',
    umbracoLocale: 'zh-hant-tw',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://tw.hotels.com'
  },
  pt_BR: {
    domain: 'https://www.hoteis.com',
    queryString: 'pos=HCOM_BR&locale=pt_BR',
    umbracoLocale: 'pt-br',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hoteis.com',
    xDefaultUmbracoLocale: 'pt-br',
    cannoical: 'https://www.hoteis.com'
  },
  nb_NO: {
    domain: 'https://no.hotels.com',
    queryString: 'pos=HCOM_NO&locale=nb_NO',
    umbracoLocale: 'nb-no',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://no.hotels.com'
  },
  sv_SE: {
    domain: 'https://sv.hotels.com',
    queryString: 'pos=HCOM_SE&locale=sv_SE',
    umbracoLocale: 'sv-se',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://sv.hotels.com'
  },
  fi_FI: {
    domain: 'https://fi.hotels.com',
    queryString: 'pos=HCOM_FI&locale=fi_FI',
    umbracoLocale: 'fi-fi',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://fi.hotels.com'
  },
  da_DK: {
    domain: 'https://da.hotels.com',
    queryString: 'pos=HCOM_DK&locale=da_DK',
    umbracoLocale: 'da-dk',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://da.hotels.com'
  },
  es_MX: {
    domain: 'https://www.hoteles.com',
    queryString: 'pos=HCOM_LATAM&locale=es_MX',
    umbracoLocale: 'es-mx',
    fallbackLocale: 'en_US',
    fallbackUmbracoLocale: 'en-us',
    xDefault: 'https://www.hoteles.com',
    xDefaultUmbracoLocale: 'es-mx',
    cannoical: 'https://www.hoteles.com'
  },
  fr_CA: {
    domain: 'https://fr.hotels.com',
    queryString: 'pos=HCOM_CA&locale=fr_CA',
    umbracoLocale: 'fr-ca',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://fr.hotels.com'
  },
  en_CA: {
    domain: 'https://ca.hotels.com',
    queryString: 'pos=HCOM_CA&locale=en_CA',
    umbracoLocale: 'en-ca',
    fallbackLocale: 'en_GB',
    fallbackUmbracoLocale: 'en-gb',
    xDefault: 'https://www.hotels.com',
    xDefaultUmbracoLocale: 'en-us',
    cannoical: 'https://ca.hotels.com'
  }
};
export var getDomain = function (locale) {
  return domainDictionary[locale];
};
export var getDomainFromUmbracoLocale = function (umbracoLocale) {
  // find the domain from the umbracoLocale, return transform object with key
  var keys = Object.keys(domainDictionary);
  for (var i = 0; i < keys.length; i++) {
    if (domainDictionary[keys[i]].umbracoLocale === umbracoLocale) {
      return __assign({
        locale: keys[i]
      }, domainDictionary[keys[i]]);
    }
  }
  // return Object.values(domainDictionary).find((domain) => domain.umbracoLocale === umbracoLocale);
};