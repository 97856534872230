var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { checkValue } from 'components/functions';
import { action, observable } from 'mobx';
import { getLinkToAttributionPage } from 'stores/helpers/storeHelpers';
import { generateSnippet, generateSnippetContent, generateSnippetDestinationHub } from 'stores/helpers/snippet-helper';
import { clickStreamExperienceMap, getDomain } from './helpers/data-maps';
import * as URL from 'url';
export var GoGuidesStore = /** @class */function (_super) {
  __extends(GoGuidesStore, _super);
  function GoGuidesStore(goGuidesSource, state, logger) {
    if (state === void 0) {
      state = {};
    }
    if (logger === void 0) {
      logger = NOOP_LOGGER;
    }
    var _this = _super.call(this, state, logger) || this;
    _this.goGuidesSource = goGuidesSource;
    _this.isRouteChecked = false;
    _this.isFallbackLanguage = false;
    _this.imageDomain = "https://goguides.azureedge.net";
    _this.canonicalUrlToReplace = "";
    _this.gaiaId = null;
    return _this;
  }
  GoGuidesStore.prototype.hydrate = function (data) {
    delete data.goGuidesSource;
    Object.assign(this, data);
  };
  GoGuidesStore.prototype.setCanonicalUrlToReplace = function (caonicalUrl) {
    this.canonicalUrlToReplace = caonicalUrl;
  };
  GoGuidesStore.prototype.getCanonicalUrlToReplace = function () {
    return this.canonicalUrlToReplace;
  };
  GoGuidesStore.prototype.setIsFallbackLanguage = function (isFallbackLanguage) {
    this.isFallbackLanguage = isFallbackLanguage;
  };
  GoGuidesStore.prototype.setIsRouteChecked = function (isRouteChecked) {
    this.isRouteChecked = isRouteChecked;
  };
  GoGuidesStore.prototype.setPageUrlData = function (pageUrlData) {
    this.pageUrlData = pageUrlData;
    this.ClicksteamExperience = clickStreamExperienceMap(pageUrlData);
  };
  GoGuidesStore.prototype.setJsonData = function (jsonData) {
    this.jsonData = jsonData;
    // set geoId required for page view
    if (jsonData.data && jsonData.data.body && jsonData.data.body.gaiaId) {
      this.gaiaId = jsonData.data.body.gaiaId;
    }
  };
  GoGuidesStore.prototype.getJsonData = function () {
    return this.jsonData;
  };
  GoGuidesStore.prototype.fetchPage = function (pageUrlData) {
    return this.jsonData;
  };
  GoGuidesStore.prototype.fetchPageData = function (pageUrlData) {
    return __awaiter(this, void 0, Promise, function () {
      var pageJson, content, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            return [4 /*yield*/, this.goGuidesSource.fetchCMSPageDataByPageUrl(pageUrlData)];
          case 1:
            pageJson = _a.sent();
            content = this.processPageJson(pageJson, pageUrlData);
            if (this.isFallbackLanguage) {
              console.log("ggcontent: fallback content " + pageUrlData.url);
            }
            return [2 /*return*/, content];
          case 2:
            error_1 = _a.sent();
            console.error('Error fetching data:', error_1);
            return [2 /*return*/, null];
          // or throw error
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };
  GoGuidesStore.prototype.processPageJson = function (pageJson, pageUrlData) {
    if ('json' === pageUrlData.source || 'dda' === pageUrlData.source) {
      return this.processJsonSource(pageJson, pageUrlData);
    } else {
      return this.processOtherSource(pageJson, pageUrlData);
    }
  };
  GoGuidesStore.prototype.processJsonSource = function (pageJson, pageUrlData) {
    var _a;
    var content;
    // process json or dda source
    if (checkValue(pageJson) && checkValue(pageJson) && ('json' == pageUrlData.source || 'dda' == pageUrlData.source)) {
      content = pageJson;
      if (checkValue(content) && checkValue((_a = content.data) === null || _a === void 0 ? void 0 : _a.body) && checkValue(content.data.body['@type'], true)) {
        this.isFallbackLanguage = content.data.body.displayFallbackLanguageContent == true;
        switch (content.data.body['@type']) {
          case 'home':
            {
              pageUrlData.contentTypeId = 1;
              break;
            }
          case 'destinationhub':
            {
              pageUrlData.contentTypeId = 2;
              break;
            }
          case 'categoryhub':
            {
              pageUrlData.contentTypeId = 3;
              break;
            }
          case 'listicle':
            {
              pageUrlData.contentTypeId = 4;
              break;
            }
          case 'longform':
            {
              pageUrlData.contentTypeId = 5;
              break;
            }
        }
        if (content.data.body['relatedStories'] && content.data.body['relatedStories'].length > 0) {
          var adjustedData = {
            relatedStories: content.data.body['relatedStories'].map(function (item) {
              var imageUrl = item.imageUrl,
                rest = __rest(item, ["imageUrl"]);
              return __assign(__assign({}, rest), {
                image: {
                  imageUrl: imageUrl
                }
              });
            })
          };
          content.data.body['relatedStories'] = adjustedData.relatedStories;
        }
      }
    }
    return content;
  };
  GoGuidesStore.prototype.processOtherSource = function (pageJson, pageUrlData) {
    var _a;
    var content;
    // process api source
    if (checkValue(pageJson) && checkValue(pageJson) && checkValue(pageJson.contentType)) {
      //https://uk.hotels.com/capsapi/template-cplite/goguides/best-things-to-do-after-dinner-marrakech/en_US/0?ispreview=1&locale=en_GB&pos=HCOM_UK&siteid=300000005
      //https://uk.hotels.com/capsapi/goguides/morocco/10-best-things-to-do-after-dinner-in-marrakech?locale=en_GB&pos=HCOM_UK&siteid=300000005
      var body = pageJson;
      var skipPage = false;
      var checkEnv = "";
      try {
        if (typeof process != 'undefined' && typeof process.env != 'undefined' && typeof process.env.EXPEDIA_ENVIRONMENT != 'undefined') {
          checkEnv = process.env.EXPEDIA_ENVIRONMENT;
        }
      } catch (e) {
        console.log(e);
      }
      if ("test" != checkEnv) {
        skipPage = !body.isLive;
      }
      if (skipPage) {
        body = {};
      } else {
        var contentType = "";
        switch (body.contentType) {
          case 'goGuidesHome':
            {
              pageUrlData.contentTypeId = 1;
              contentType = 'home';
              break;
            }
          case 'goGuidesDestinationPageTemplate':
            {
              pageUrlData.contentTypeId = 2;
              contentType = 'destinationhub';
              break;
            }
          case 'goGuidesDestinationThemeTemplate':
            {
              pageUrlData.contentTypeId = 3;
              contentType = 'categoryhub';
              break;
            }
          case 'goGuidesListicleTemplate':
            {
              pageUrlData.contentTypeId = 4;
              contentType = 'listicle';
              break;
            }
          case 'goGuidesLongFormTemplate':
            {
              pageUrlData.contentTypeId = 5;
              contentType = 'longform';
              break;
            }
        }
        if (checkValue(contentType)) {
          body['@type'] = contentType;
        }
        this.generateImage(body);
        this.generateFromGaiaData(body);
        body.locale = pageUrlData.locale;
        // need help about Umbraco locale
        if (body.isDefaultLocaleDataReturned) {
          this.isFallbackLanguage = true;
          var fallBackDomain = getDomain(pageUrlData.locale);
          body.locale = fallBackDomain.fallbackLocale;
        }
        body.displayFallbackLanguageContent = this.isFallbackLanguage == true;
        if (body && body.introText && checkValue(body.introText.markup)) {
          body.introText = body.introText.markup;
        }
        if ('home' == contentType) {
          //hardcode for homePage
          body['contentSource'] = 'cit_original';
          // TopDestinations
          this.generateTopDestinations(body);
          // FeaturedStories
          this.generateFeaturedStories(body, "items");
        } else if ('destinationhub' == contentType) {
          // TopDestinations
          // State usa/washington
          // Region china/hainan
          if (["continent", "country", "region", "state"].includes(body.destinationType)) {
            this.generateTopDestinations(body);
          }
          // Freetext
          this.generateFreetext(body);
          // FeaturedStories
          if (["country", "region", "state"].includes(body.destinationType)) {
            // this.generateFeaturedStories(body);
          }
          // Trending now
          if (["continent", "neighborhood"].includes(body.destinationType)) {
            this.generateFeaturedStories(body, "trendingNow");
          }
          // Main HighLight, Category HighLight
          if (["country", "region", "state", "city"].includes(body.destinationType)) {
            this.generateCategoryHighLight(body);
          }
          // WhatsAround
          this.generateWhatsAround(body);
          // OtherCategories will be json
          this.generateOtherCategories(body);
          // Stories from contributors [contributorsStories]
          this.generateContributorsStories(body);
          // AlsoPopular
          this.generateAlsoPopular(body);
          // Other Neighbourhoods2
          if (["city", "neighborhood"].includes(body.destinationType)) {
            this.generateOtherNeighbourhoods(body);
          }
        } else if ('categoryhub' == contentType) {
          // FeaturedStories
          this.generateFeaturedStories(body, "items");
          if (body.categoryCode && body.categoryCode.length > 0 && checkValue((_a = body.categoryCode[0].properties) === null || _a === void 0 ? void 0 : _a.parentCode, true)) {
            body.categoryCode = body.categoryCode[0].properties.parentCode;
          }
          // categoryCodes
          this.generateCategoryCodes(body);
          //OtherCategories
          this.generateOtherCategories(body);
          // Stories from contributors [contributorsStories]
          this.generateContributorsStories(body);
          // AlsoPopular
          this.generateAlsoPopular(body);
          // WhatsAround
          this.generateWhatsAround(body);
        } else if ('listicle' == contentType) {
          this.generateRelatedStories(body);
          this.generateWriter(body);
          body.tagCodeNames = body.segmentCodes;
          body.omnitureSiteSectionCode = body.listicleGroup;
          // ItemBucket
          var tmpItemBucket = this.generateItemBucket(body);
          delete body.items;
          if (checkValue(tmpItemBucket) && Array.isArray(tmpItemBucket) && tmpItemBucket.length > 0) {
            body.items = tmpItemBucket;
          }
          // categoryCodes
          this.generateCategoryCodes(body);
          if (checkValue(body.hideNumber)) {
            body["features"] = {
              "hideNumber": true
            };
          }
        } else if ('longform' == contentType) {
          this.generateRelatedStories(body);
          this.generateWriter(body);
          var tmpGeo = this.generateGeo(body.latLng);
          if (checkValue(tmpGeo) || checkValue(body.location, true) || checkValue(body.openingHours, true) || checkValue(body.phoneNumber, true) || checkValue(body.price, true)) {
            var info = {};
            if (checkValue(body.location, true)) {
              info["location"] = body.location;
            }
            if (checkValue(body.openingHours, true)) {
              info["openingHours"] = body.openingHours;
            }
            if (checkValue(body.phoneNumber, true)) {
              info["phoneNumber"] = body.phoneNumber;
            }
            if (checkValue(body.price, true)) {
              info["price"] = body.price;
            }
            if (checkValue(tmpGeo)) {
              info["geo"] = tmpGeo;
            }
            body.info = info;
          }
          //content
          this.generateContent(body);
          // categoryCodes
          this.generateCategoryCodes(body);
          // categoryLinks - must process this after this.generateCategoryCodes(body);
          this.generateCategoryLinks(body);
        }
      }
      content = {
        data: {
          body: body
        }
      };
    }
    return content;
  };
  GoGuidesStore.prototype.setupStore = function (pageJson, locale, contentLocale) {
    var _a;
    var content;
    if (checkValue(pageJson) && checkValue(pageJson.contentType)) {
      var body = pageJson;
      if (body.skipPage) {
        body = {};
      } else {
        var contentType = "";
        switch (body.contentType) {
          case 'goGuidesHome':
            {
              this.pageUrlData = {
                contentTypeId: 1
              };
              contentType = 'home';
              break;
            }
          case 'goGuidesDestinationPageTemplate':
            {
              this.pageUrlData = {
                contentTypeId: 2
              };
              contentType = 'destinationhub';
              break;
            }
          case 'goGuidesDestinationThemeTemplate':
            {
              this.pageUrlData = {
                contentTypeId: 3
              };
              contentType = 'categoryhub';
              break;
            }
          case 'goGuidesListicleTemplate':
            {
              this.pageUrlData = {
                contentTypeId: 4
              };
              contentType = 'listicle';
              break;
            }
          case 'goGuidesLongFormTemplate':
            {
              this.pageUrlData = {
                contentTypeId: 5
              };
              contentType = 'longform';
              break;
            }
        }
        if (checkValue(contentType)) {
          body['@type'] = contentType;
        }
        this.generateImage(body);
        this.generateFromGaiaData(body);
        body.locale = locale;
        // need help about Umbraco locale
        if (body.isDefaultLocaleDataReturned) {
          this.isFallbackLanguage = true;
          var fallBackDomain = getDomain(contentLocale);
          body.locale = fallBackDomain.fallbackLocale;
        }
        body.displayFallbackLanguageContent = this.isFallbackLanguage == true;
        if (body && body.introText && checkValue(body.introText.markup)) {
          body.introText = body.introText.markup;
        }
        if ('home' == contentType) {
          //hardcode for homePage
          body['contentSource'] = 'cit_original';
          // TopDestinations
          this.generateTopDestinations(body);
          // FeaturedStories
          this.generateFeaturedStories(body, "items");
        } else if ('destinationhub' == contentType) {
          // TopDestinations
          // State usa/washington
          // Region china/hainan
          if (["continent", "country", "region", "state"].includes(body.destinationType)) {
            this.generateTopDestinations(body);
          }
          // Freetext
          this.generateFreetext(body);
          // FeaturedStories
          if (["country", "region", "state"].includes(body.destinationType)) {
            // this.generateFeaturedStories(body);
          }
          // Trending now
          if (["continent", "neighborhood"].includes(body.destinationType)) {
            this.generateFeaturedStories(body, "trendingNow");
          }
          // Main HighLight, Category HighLight
          if (["country", "region", "state", "city"].includes(body.destinationType)) {
            this.generateCategoryHighLight(body);
          }
          // WhatsAround
          this.generateWhatsAround(body);
          // OtherCategories will be json
          this.generateOtherCategories(body);
          // Stories from contributors [contributorsStories]
          this.generateContributorsStories(body);
          // AlsoPopular
          this.generateAlsoPopular(body);
          // Other Neighbourhoods2
          if (["city", "neighborhood"].includes(body.destinationType)) {
            this.generateOtherNeighbourhoods(body);
          }
        } else if ('categoryhub' == contentType) {
          // FeaturedStories
          this.generateFeaturedStories(body, "items");
          if (body.categoryCode && body.categoryCode.length > 0 && checkValue((_a = body.categoryCode[0].properties) === null || _a === void 0 ? void 0 : _a.parentCode, true)) {
            body.categoryCode = body.categoryCode[0].properties.parentCode;
          }
          // categoryCodes
          this.generateCategoryCodes(body);
          //OtherCategories
          this.generateOtherCategories(body);
          // Stories from contributors [contributorsStories]
          this.generateContributorsStories(body);
          // AlsoPopular
          this.generateAlsoPopular(body);
          // WhatsAround
          this.generateWhatsAround(body);
        } else if ('listicle' == contentType) {
          this.generateRelatedStories(body);
          this.generateWriter(body);
          body.tagCodeNames = body.segmentCodes;
          body.omnitureSiteSectionCode = body.listicleGroup;
          // ItemBucket
          var tmpItemBucket = this.generateItemBucket(body);
          delete body.items;
          if (checkValue(tmpItemBucket) && Array.isArray(tmpItemBucket) && tmpItemBucket.length > 0) {
            body.items = tmpItemBucket;
          }
          // categoryCodes
          this.generateCategoryCodes(body);
          if (checkValue(body.hideNumber)) {
            body["features"] = {
              "hideNumber": true
            };
          }
        } else if ('longform' == contentType) {
          this.generateRelatedStories(body);
          this.generateWriter(body);
          var tmpGeo = this.generateGeo(body.latLng);
          if (checkValue(tmpGeo) || checkValue(body.location, true) || checkValue(body.openingHours, true) || checkValue(body.phoneNumber, true) || checkValue(body.price, true)) {
            var info = {};
            if (checkValue(body.location, true)) {
              info["location"] = body.location;
            }
            if (checkValue(body.openingHours, true)) {
              info["openingHours"] = body.openingHours;
            }
            if (checkValue(body.phoneNumber, true)) {
              info["phoneNumber"] = body.phoneNumber;
            }
            if (checkValue(body.price, true)) {
              info["price"] = body.price;
            }
            if (checkValue(tmpGeo)) {
              info["geo"] = tmpGeo;
            }
            body.info = info;
          }
          //content
          this.generateContent(body);
          // categoryCodes
          this.generateCategoryCodes(body);
          // categoryLinks - must process this after this.generateCategoryCodes(body);
          this.generateCategoryLinks(body);
        }
        this.ClicksteamExperience = clickStreamExperienceMap(this.pageUrlData);
      }
      content = {
        data: {
          body: body
        }
      };
    }
    this.setJsonData(content);
    /* TODO: Check if this is needed */
    this.setIsRouteChecked(true);
  };
  GoGuidesStore.prototype.generateWriter = function (body) {
    var writer = body.writer && body.writer.length > 0 ? body.writer : [];
    if (writer.length > 0) {
      var item = writer[0];
      var writerName = item.properties.writerName ? item.properties.writerName : '';
      var imageUrl = item.properties.image && item.properties.image.length > 0 ? this.imageDomain + item.properties.image[0].url : null;
      var descriptorKey = item.properties.descriptorKey ? item.properties.descriptorKey : '';
      if (writerName !== '' && descriptorKey !== '') {
        var tmpWriter = {
          "name": writerName,
          "imageUrl": imageUrl,
          "descriptorKey": descriptorKey
        };
        delete body.writer;
        body.writer = tmpWriter;
      }
    }
  };
  GoGuidesStore.prototype.generateRelatedStories = function (body) {
    var tmpStories = [];
    if (body && body.stories) {
      tmpStories = this.generateTeaserList(body.stories, {
        hasPropertiesNode: true,
        country: true,
        isRelatedStories: true
      });
    }
    delete body.stories;
    if (tmpStories.length > 0) {
      body.relatedStories = tmpStories;
    }
  };
  GoGuidesStore.prototype.removeQueryString = function (url) {
    var newUrl = checkValue(url, true) ? url.split('?')[0] : url;
    return newUrl;
  };
  GoGuidesStore.prototype.removeQueryStringFromTargetUrl = function (json) {
    var _this = this;
    if (typeof json === 'object' && json !== null) {
      if (Array.isArray(json)) {
        json.forEach(function (item) {
          return _this.removeQueryStringFromTargetUrl(item);
        });
      } else {
        for (var key in json) {
          if ('targetUrl' == key) {
            json[key] = this.removeQueryString(json[key]);
          } else {
            this.removeQueryStringFromTargetUrl(json[key]);
          }
        }
      }
    }
  };
  GoGuidesStore.prototype.generateDestinationList = function (body) {
    body.destinationList = null;
    if (body && body.gaia && body.gaia[0] && checkValue(body.gaia[0].destinationList)) {
      try {
        var json = body.gaia[0].destinationList;
        if (checkValue(json) && Object.keys(json).length > 0) {
          this.removeQueryStringFromTargetUrl(json);
          body.destinationList = json;
        }
      } catch (e) {}
    }
  };
  GoGuidesStore.prototype.generateBreadCrumb = function (body) {
    body.breadcrumb = [];
    if (body && 'home' != body['@type'] && body.gaia && body.gaia[0] && checkValue(body.gaia[0].breadcrumb)) {
      try {
        // let json = JSON.parse(body.gaia[0].breadcrumb);
        var json = body.gaia[0].breadcrumb;
        if (checkValue(json) && json.length > 0) {
          this.removeQueryStringFromTargetUrl(json);
          body.breadcrumb = json;
          var gaiaNode = body.gaia[0];
          var destinationPage = gaiaNode.destinationPage;
          var tmpDestination = {};
          if (checkValue(destinationPage) && destinationPage.length > 0) {
            var destinationPageContent = destinationPage[0].properties;
            if (checkValue(destinationPageContent)) {
              tmpDestination['name'] = destinationPageContent.h1;
              tmpDestination['targetUrl'] = this.getGoGuidesUrl(destinationPageContent);
              var gaiaDhub = "";
              try {
                gaiaDhub = destinationPageContent['gaia'][0]['properties']['gaiaId'];
              } catch (e) {}
              if (checkValue(tmpDestination['targetUrl']) && body.gaia[0]['gaiaId'] == gaiaDhub) {
                body.breadcrumb[body.breadcrumb.length - 1] = tmpDestination;
              }
            }
          }
        }
      } catch (e) {}
    }
  };
  GoGuidesStore.prototype.generateMenu = function (body) {
    var _this = this;
    body.menu = null;
    // homePage and content World level will have body.destinationId is null
    if (body && body.gaia && body.gaia[0] && checkValue(body.destinationId)) {
      var menu = {
        "home": {
          "name": "Go Guides",
          "targetUrl": this.getGoGuidesUrl({
            country: null,
            slug: ""
          })
        }
      };
      var gaiaNode_1 = body.gaia[0];
      var tmpDestination = {};
      var tmpNodeName = void 0;
      // only work on this if there's breadcrumb
      if (body.breadcrumb.length > 0) {
        if ('neighborhood' == body.destinationType) {
          tmpNodeName = body.breadcrumb[body.breadcrumb.length - 2];
        } else {
          tmpNodeName = body.breadcrumb[body.breadcrumb.length - 1];
        }
        if (checkValue(tmpNodeName['name'], true)) {
          tmpDestination['name'] = tmpNodeName['name'];
        }
        if (checkValue(tmpNodeName['targetUrl'], true)) {
          tmpDestination['targetUrl'] = tmpNodeName['targetUrl'];
        }
        if (checkValue(tmpDestination['name'])) {
          menu['destination'] = tmpDestination;
        }
      }
      var categoryList = [{
        nodeName: "thingsToDoHub",
        categoryCode: "things_to_do"
      }, {
        nodeName: "restaurantHub",
        categoryCode: "food"
      }, {
        nodeName: "shoppingHub",
        categoryCode: "shopping"
      }, {
        nodeName: "nightlifeHub",
        categoryCode: "nightlife"
      }, {
        nodeName: "toursHub",
        categoryCode: "tours"
      }, {
        nodeName: "informationHub",
        categoryCode: "information"
      }, {
        nodeName: "blogHub",
        categoryCode: "blogs"
      }];
      var categories_1 = [];
      categoryList.map(function (category, index) {
        var tmpCategory = {};
        var categoryPage = gaiaNode_1[category.nodeName];
        if (categoryPage && categoryPage.length > 0) {
          //temporary fix
          var categoryPageContent = categoryPage[0].properties;
          if (checkValue(categoryPageContent.slug)) {
            tmpCategory['categoryKey'] = category.categoryCode;
            tmpCategory['targetUrl'] = _this.getGoGuidesUrl(categoryPageContent);
            categories_1.push(tmpCategory);
          }
        }
      });
      if (categories_1.length > 0) {
        menu['category'] = categories_1;
      }
      body.menu = menu;
    }
  };
  GoGuidesStore.prototype.getSortValueForCategoryLinks = function (sortKey, key) {
    return sortKey[key] || Infinity;
  };
  GoGuidesStore.prototype.generateCategoryLinks = function (body) {
    var _this = this;
    var tmpCategoryLinks = [];
    if (body && checkValue(body.categoryCodes)) {
      var tmpCategoryKeys = Object.keys(body.categoryCodes);
      var tmpSegmentCodes = body.segmentCodes || [];
      var newSegmentCodes = tmpSegmentCodes.map(function (item, index) {
        var newItem = item;
        newItem = newItem == "shoppers" ? "shopping" : newItem;
        newItem = newItem == "nightlife_b595ae4" ? "nightlife" : newItem;
        return newItem;
      });
      // let categoryKeys = [...tmpCategoryKeys, ...newSegmentCodes];//[...new Set([...a, ...b])];
      var categoryKeys = __spreadArray([], __read(new Set(__spreadArray(__spreadArray([], __read(tmpCategoryKeys), false), __read(newSegmentCodes), false))), false);
      var categoryLinks_1 = body.categoryLinks || [];
      if (categoryKeys.length > 0) {
        var sortKey_1 = {
          things_to_do: 1,
          food: 3,
          shopping: 4,
          nightlife: 5,
          tours: 7,
          information: 8,
          blogs: 9,
          hotels: 10
        };
        var maximumShowCategoryLinks_1 = 3;
        categoryKeys.sort(function (a, b) {
          return _this.getSortValueForCategoryLinks(sortKey_1, a) - _this.getSortValueForCategoryLinks(sortKey_1, b);
        });
        categoryKeys.map(function (categoryKey, index) {
          var e_1, _a;
          var _b;
          if (checkValue(sortKey_1[categoryKey])) {
            var tmpCategoryLink = {
              name: categoryKey
            };
            try {
              for (var categoryLinks_2 = __values(categoryLinks_1), categoryLinks_2_1 = categoryLinks_2.next(); !categoryLinks_2_1.done; categoryLinks_2_1 = categoryLinks_2.next()) {
                var categoryLink = categoryLinks_2_1.value;
                if (categoryKey == ((_b = categoryLink.properties) === null || _b === void 0 ? void 0 : _b.categoryCode)) {
                  tmpCategoryLink['targetUrl'] = _this.getGoGuidesUrl(categoryLink.properties);
                  break;
                }
              }
            } catch (e_1_1) {
              e_1 = {
                error: e_1_1
              };
            } finally {
              try {
                if (categoryLinks_2_1 && !categoryLinks_2_1.done && (_a = categoryLinks_2.return)) _a.call(categoryLinks_2);
              } finally {
                if (e_1) throw e_1.error;
              }
            }
            if (tmpCategoryLinks.length < maximumShowCategoryLinks_1) {
              tmpCategoryLinks.push(tmpCategoryLink);
            }
          }
        });
        if (tmpCategoryLinks.length > 0) {
          tmpCategoryLinks.sort(function (a, b) {
            return sortKey_1[a.name] - sortKey_1[b.name];
          });
        }
      }
    }
    delete body.categoryLinks;
    if (tmpCategoryLinks.length > 0) {
      body.categoryLinks = tmpCategoryLinks;
    }
  };
  GoGuidesStore.prototype.generateContent = function (body) {
    var _this = this;
    if (body && body.content) {
      body.content.map(function (item, index) {
        var _a;
        if ("longFormImage" == item.contentType) {
          _this.generateImage(item);
          _this.generateImageAttribution(item);
        } else if ("longFormText" == item.contentType) {
          if (checkValue((_a = item.html) === null || _a === void 0 ? void 0 : _a.markup, true)) {
            item.html.paragraph = item.html.markup;
            delete item.html.markup;
          }
        }
      });
    }
  };
  GoGuidesStore.prototype.generateTeaserList = function (items, attributeParam) {
    var _this = this;
    if (attributeParam === void 0) {
      attributeParam = {};
    }
    var attributeDefault = {
      hasPropertiesNode: false,
      country: false,
      city: false,
      snippet: false,
      isTopDestination: false,
      isTrendingNow: false,
      isContributorsStories: false,
      isOtherNeighbourhoods: false,
      isRelatedStories: false,
      pageDestinationType: "",
      pageLocale: ""
    };
    var attribute = Object.assign({}, attributeDefault, attributeParam);
    var tmpTeaserList = [];
    if (items && items.length > 0) {
      items.map(function (item, index) {
        var _a;
        var isOk = true;
        var itemProperties = attribute.hasPropertiesNode ? item.properties : item;
        var tmpTeaser = {};
        tmpTeaser['name'] = itemProperties.h1;
        tmpTeaser['targetUrl'] = _this.getGoGuidesUrl(itemProperties);
        _this.generateImage(itemProperties);
        tmpTeaser['image'] = itemProperties.image;
        if (attribute.snippet) {
          var introText = _this.getIntroText(itemProperties);
          // top destination, other neighbourhoods
          if (attribute.isTopDestination || attribute.isOtherNeighbourhoods) {
            var destinationType = checkValue(attribute.pageDestinationType, true) ? attribute.pageDestinationType : "";
            var isCountryLevelPage = "country" == destinationType;
            tmpTeaser['snippetText'] = generateSnippetDestinationHub(introText, isCountryLevelPage, items.length, index + 1, attribute.pageLocale);
          }
          // Trending Now
          if (attribute.isTrendingNow) {
            tmpTeaser['snippetText'] = generateSnippetContent(introText, items.length, index + 1, attribute.pageLocale);
          }
        }
        // alsoPopular
        if (attribute.country) {
          tmpTeaser['countryName'] = itemProperties.countryName;
        }
        // alsoPopular
        if (attribute.city) {
          tmpTeaser['cityName'] = itemProperties.cityName;
        }
        // contributors stories
        if (attribute.isContributorsStories) {
          isOk = "blogs" == itemProperties.listicleGroup;
        }
        // relatedStories
        if (attribute.isRelatedStories) {
          tmpTeaser['isBlog'] = "blogs" == itemProperties.listicleGroup;
        }
        // Other Neighbourhoods
        if (attribute.isOtherNeighbourhoods) {
          if (itemProperties.gaia && itemProperties.gaia.length > 0 && checkValue((_a = itemProperties.gaia[0].properties) === null || _a === void 0 ? void 0 : _a.gaiaId, true)) {
            tmpTeaser['seeAllPropertiesUrl'] = "/Hotel-Search?regionId=" + itemProperties.gaia[0].properties.gaiaId;
          }
        }
        isOk = isOk && checkValue(tmpTeaser['name'], true);
        if (isOk) {
          tmpTeaserList.push(tmpTeaser);
        }
      });
    }
    return tmpTeaserList;
  };
  GoGuidesStore.prototype.generateContributorsStories = function (body) {
    var tmpContributorsStories = [];
    if (body && body.contributorStories) {
      tmpContributorsStories = this.generateTeaserList(body.contributorStories, {
        hasPropertiesNode: true,
        isContributorsStories: true
      });
    }
    delete body.contributorStories;
    if (tmpContributorsStories.length > 0) {
      body.contributorsStories = {
        storiesList: tmpContributorsStories
      };
      if (checkValue(body.seeAllStoriesLink, true)) {
        body.contributorsStories.seeAllStoriesLink = {
          targetUrl: this.removeQueryString(body.seeAllStoriesLink)
        };
      }
    }
  };
  GoGuidesStore.prototype.generateOtherNeighbourhoods = function (body) {
    var tmpOtherNeighbourhoods = [];
    if (body && body.otherNeighbourhoods2Items) {
      tmpOtherNeighbourhoods = this.generateTeaserList(body.otherNeighbourhoods2Items, {
        hasPropertiesNode: true,
        snippet: true,
        isOtherNeighbourhoods: true
      });
    }
    delete body.otherNeighbourhoods2Items;
    if (tmpOtherNeighbourhoods.length > 0) {
      body.otherNeighbourhoods2 = {
        neighbourhoodsDestinationName: body.otherNeighbourhoods2DestinationName,
        neighbourhoodsSeeAllUrl: this.getUrlWithParameter(body.otherNeighbourhoods2SeeAllUrl, ["regionId"]),
        neighbourhoodsList: tmpOtherNeighbourhoods
      };
    }
  };
  GoGuidesStore.prototype.getUrlWithParameter = function (link, allowParams) {
    var e_2, _a;
    var newUrl = link;
    try {
      var parsedUrl = URL.parse(link, true);
      var query = parsedUrl.query;
      var newQuery = {};
      try {
        for (var allowParams_1 = __values(allowParams), allowParams_1_1 = allowParams_1.next(); !allowParams_1_1.done; allowParams_1_1 = allowParams_1.next()) {
          var param = allowParams_1_1.value;
          if (query[param] !== undefined) {
            newQuery[param] = query[param];
          }
        }
      } catch (e_2_1) {
        e_2 = {
          error: e_2_1
        };
      } finally {
        try {
          if (allowParams_1_1 && !allowParams_1_1.done && (_a = allowParams_1.return)) _a.call(allowParams_1);
        } finally {
          if (e_2) throw e_2.error;
        }
      }
      newUrl = "".concat(parsedUrl.pathname, "?").concat(new URLSearchParams(newQuery).toString());
    } catch (e) {}
    return newUrl;
  };
  GoGuidesStore.prototype.generateAlsoPopular = function (body) {
    var tmpAlsoPopulars = [];
    if (body && body.alsoPopular) {
      tmpAlsoPopulars = this.generateTeaserList(body.alsoPopular, {
        country: true,
        city: true,
        hasPropertiesNode: true
      });
    }
    delete body.alsoPopular;
    if (tmpAlsoPopulars.length > 0) {
      body.alsoPopular = tmpAlsoPopulars;
    }
  };
  GoGuidesStore.prototype.generateCategoryCodes = function (body) {
    var tmpCategoryCodes = {};
    if (body && body.categoryCodes) {
      body.categoryCodes.map(function (item, index) {
        var itemProperties = item.properties;
        var parentCode = itemProperties.parentCode;
        var code = itemProperties.code;
        if (checkValue(parentCode, true)) {
          if (!(parentCode in tmpCategoryCodes)) {
            tmpCategoryCodes[parentCode] = [];
          }
          if (checkValue(code, true)) {
            tmpCategoryCodes[parentCode].push(code);
          }
        } else {
          if (!(code in tmpCategoryCodes)) {
            tmpCategoryCodes[code] = [];
          }
        }
      });
    }
    delete body.categoryCodes;
    if (Object.keys(tmpCategoryCodes).length > 0) {
      body.categoryCodes = tmpCategoryCodes;
    }
  };
  GoGuidesStore.prototype.generateWhatsAround = function (body) {
    var tmpWhatsAround = [];
    if (body && body.whatsAround) {
      tmpWhatsAround = this.generateTeaserList(body.whatsAround, {
        hasPropertiesNode: true
      });
    }
    delete body.whatsAround;
    if (tmpWhatsAround.length > 0) {
      body.whatsAround = tmpWhatsAround;
    }
  };
  GoGuidesStore.prototype.generateCategoryHighLight = function (body) {
    var _this = this;
    body.mainHighlight = [];
    body.categoryHighlight = [];
    var categoryList = [{
      nodeName: "thingsToDoHub",
      categoryCode: "THINGS_TO_DO"
    }, {
      nodeName: "restaurantHub",
      categoryCode: "RESTAURANT"
    }, {
      nodeName: "shoppingHub",
      categoryCode: "SHOPPING"
    }, {
      nodeName: "nightlifeHub",
      categoryCode: "NIGHTLIFE"
    }, {
      nodeName: "informationHub",
      categoryCode: "INFORMATION"
    }];
    if (body && body.gaia && body.gaia.length > 0) {
      categoryList.map(function (category, index) {
        var content = body.gaia[0][category.nodeName];
        if (content && content.length > 0) {
          var tmpCategoryHighLight = {};
          var tmpContent = content[0].properties;
          if ("goGuidesDestinationThemeTemplate" == content[0].contentType) {
            tmpCategoryHighLight['items'] = tmpContent['items'];
            tmpCategoryHighLight['snippetText'] = generateSnippet(_this.getIntroText(tmpContent), 70, body.locale);
            tmpCategoryHighLight['sellAllLink'] = {
              linkText: "Show more",
              targetUrl: _this.getGoGuidesUrl(tmpContent)
            };
          } else {
            var tmpItems_1 = {};
            tmpItems_1['properties'] = content[0].properties;
            tmpCategoryHighLight['items'] = [tmpItems_1];
          }
          var tmpCategoryHighLightItems = void 0;
          var tmpItems = tmpCategoryHighLight['items'];
          var maxItem = "thingsToDoHub" == category.nodeName ? 9 : 3;
          if (tmpItems && tmpItems.length > maxItem) {
            tmpCategoryHighLightItems = tmpItems.slice(0, maxItem);
          } else {
            tmpCategoryHighLightItems = tmpItems;
          }
          var tmpNode = {
            items: tmpCategoryHighLightItems,
            locale: body.locale
          };
          delete tmpCategoryHighLight['items'];
          tmpCategoryHighLight['items'] = _this.generateTeaserList(tmpNode.items, {
            hasPropertiesNode: true,
            snippet: true,
            isTrendingNow: true,
            pageLocale: tmpNode.locale
          });
          tmpCategoryHighLight['categoryCode'] = category.categoryCode;
          if ("thingsToDoHub" == category.nodeName) {
            body.mainHighlight.push(tmpCategoryHighLight);
          } else {
            body.categoryHighlight.push(tmpCategoryHighLight);
          }
        }
      });
    }
  };
  GoGuidesStore.prototype.generateFeaturedStories = function (body, nodeName) {
    var tmpFeaturedStories = [];
    if (body && body[nodeName]) {
      var attributeParam = {
        snippet: true,
        isTrendingNow: true,
        pageLocale: body.locale
      };
      if ("trendingNow" == nodeName) {
        attributeParam["hasPropertiesNode"] = true;
      }
      tmpFeaturedStories = this.generateTeaserList(body[nodeName], attributeParam);
    }
    delete body[nodeName];
    delete body.items;
    if (tmpFeaturedStories.length > 0) {
      body.items = tmpFeaturedStories;
    }
  };
  GoGuidesStore.prototype.generateFreetext = function (body) {
    var _a;
    if (checkValue(body.freeTextHeaderKey, true) && checkValue((_a = body.freeTextContent) === null || _a === void 0 ? void 0 : _a.markup, true)) {
      body.freeText = {
        headerKey: body.freeTextHeaderKey,
        content: body.freeTextContent.markup
      };
    }
  };
  GoGuidesStore.prototype.getGoGuidesUrl = function (item) {
    var goGuidesUrl = "/go";
    if (checkValue(item.country)) {
      goGuidesUrl = goGuidesUrl + "/" + item.country;
    }
    goGuidesUrl = goGuidesUrl + "/" + item.slug;
    return goGuidesUrl;
  };
  GoGuidesStore.prototype.generateTopDestinations = function (body) {
    var tmpTopDestinations = [];
    var topDestinations = body.topDestinations;
    var hasPropertiesNode = true;
    if ("home" == body['@type']) {
      topDestinations = body.destinationItems;
      hasPropertiesNode = false;
    }
    if (body && topDestinations) {
      tmpTopDestinations = this.generateTeaserList(topDestinations, {
        hasPropertiesNode: hasPropertiesNode,
        snippet: true,
        isTopDestination: true,
        pageDestinationType: body.destinationType,
        pageLocale: body.locale
      });
    }
    delete body.destinationItems;
    delete body.topDestinations;
    if (tmpTopDestinations.length > 0) {
      body.topDestinations = tmpTopDestinations;
    }
  };
  GoGuidesStore.prototype.generateOtherCategories = function (body) {
    var _this = this;
    body.otherCategories = null;
    if (body && body.gaia && body.gaia.length > 0) {
      var gaiaNode_2 = body.gaia[0];
      var categoryList = [{
        nodeName: "thingsToDoHub",
        categoryCode: "things_to_do",
        imageUrl: "https://a.cdn-hotels.com/gdcs/production198/d1399/be08fb5d-2d67-4461-a6b2-63d3c40014b7.jpg"
      }, {
        nodeName: "restaurantHub",
        categoryCode: "food",
        imageUrl: "https://a.cdn-hotels.com/gdcs/production40/d1052/52093590-9f56-42c5-a147-1770c0fcd493.jpg"
      }, {
        nodeName: "shoppingHub",
        categoryCode: "shopping",
        imageUrl: "https://a.cdn-hotels.com/gdcs/production196/d847/a1419d7f-e088-4b46-8822-375da35ddb22.jpg"
      }, {
        nodeName: "nightlifeHub",
        categoryCode: "nightlife",
        imageUrl: "https://a.cdn-hotels.com/gdcs/production96/d1863/f96b8e99-6ced-4974-badf-06a6ccef6ce7.jpg"
      }, {
        nodeName: "toursHub",
        categoryCode: "tours",
        imageUrl: "https://a.cdn-hotels.com/gdcs/production49/d903/f8962492-194e-4453-8275-904ca4c04dba.jpg"
      }, {
        nodeName: "informationHub",
        categoryCode: "information",
        imageUrl: "https://a.cdn-hotels.com/gdcs/production105/d960/eec79967-eccf-4d8a-9de1-55e86a4f7108.jpg"
      }, {
        nodeName: "blogHub",
        categoryCode: "blogs",
        imageUrl: "https://a.cdn-hotels.com/gdcs/production76/d1321/967f56c4-28b5-40e4-b5a0-98e841b65c40.jpg"
      }];
      var categories_2 = [];
      categoryList.map(function (category, index) {
        var tmpCategory = {};
        var categoryPage = gaiaNode_2[category.nodeName];
        if (categoryPage && categoryPage.length > 0 && categoryPage[0].properties.slug) {
          var tmpContent = categoryPage[0].properties;
          tmpCategory['image'] = {};
          if ("goGuidesDestinationThemeTemplate" == categoryPage[0].contentType) {
            if (checkValue(tmpContent)) {
              _this.generateImage(tmpContent);
              tmpCategory['image'] = tmpContent.image;
            }
          }
          if (!checkValue(tmpCategory['image']['imageUrl'])) {
            tmpCategory['image']['imageUrl'] = category.imageUrl;
          }
          tmpCategory['targetUrl'] = _this.getGoGuidesUrl(tmpContent);
          tmpCategory['categoryCode'] = category.categoryCode;
          categories_2.push(tmpCategory);
        }
      });
      if (categories_2.length > 0) {
        body['otherCategories'] = {
          "category": categories_2
        };
      }
    }
  };
  GoGuidesStore.prototype.ifNullDeleteThisNode = function (item, name) {
    if (!checkValue(item[name])) {
      delete item[name];
    }
  };
  GoGuidesStore.prototype.generateImageAttribution = function (item) {
    if (checkValue(item.image) && checkValue(item.attributionVersion, true)) {
      var link = checkValue(item.attributionVersion, true) ? getLinkToAttributionPage(item.attributionVersion) : null;
      var url = void 0;
      if (checkValue(link)) {
        url = link.url;
      }
      item.image.attribution = {
        "photographerName": checkValue(item.imageCredit) ? item.imageCredit : "",
        "linkToOriginal": item.linkToOriginal,
        "attributionVersion": item.attributionVersion,
        "linkToAttributionPage": url
      };
    }
  };
  GoGuidesStore.prototype.getIntroText = function (item) {
    var introText;
    if (item && item.introText && checkValue(item.introText.markup)) {
      introText = item.introText.markup;
    }
    if ("<p><br></p>" == introText) {
      introText = "";
    }
    return introText;
  };
  GoGuidesStore.prototype.generatePageDestinationInfo = function (body) {
    body.destinationId = null;
    body.gaiaId = null;
    body.destinationName = null;
    if (body && body.gaia && body.gaia[0]) {
      if (checkValue(body.gaia[0].destinationId)) {
        body.destinationId = body.gaia[0].destinationId;
      }
      if (checkValue(body.gaia[0].gaiaId)) {
        body.gaiaId = body.gaia[0].gaiaId;
      }
      if (checkValue(body.gaia[0].name)) {
        body.destinationName = body.gaia[0].name;
      }
    }
  };
  GoGuidesStore.prototype.generateFromGaiaData = function (body) {
    this.generatePageDestinationInfo(body);
    this.generateBreadCrumb(body); // BreadCrumb must process before menu
    this.generateMenu(body); // menu must process after BreadCrumb
    this.generateDestinationList(body);
  };
  GoGuidesStore.prototype.generateImage = function (item) {
    var tmpImage;
    if (item && item.image && item.image[0] && checkValue(item.image[0].url)) {
      tmpImage = {
        imageUrl: this.generateImageUrl(item.image[0].url)
      };
      delete item.image;
    }
    if (checkValue(tmpImage)) {
      item.image = tmpImage;
    }
  };
  GoGuidesStore.prototype.generateGeo = function (latlng) {
    var geo;
    if (checkValue(latlng, true)) {
      var splitLatlng = latlng.split(',');
      if (splitLatlng.length == 2) {
        geo = {
          "coord": {
            "lat": splitLatlng[0].trim(),
            "lon": splitLatlng[1].trim()
          }
        };
      }
    }
    return geo;
  };
  GoGuidesStore.prototype.generateItemBucket = function (body) {
    var _this = this;
    var tmpItemBucket = [];
    if (body && body.items) {
      body.items.map(function (item, index) {
        var image;
        item.name = item.title;
        delete item.title;
        item.tagLine = item.tagline;
        delete item.tagline;
        _this.generateImage(item);
        _this.generateImageAttribution(item);
        if (item.description && checkValue(item.description.markup, true)) {
          var description = item.description.markup;
          delete item.description;
          item.description = description;
        } else {
          item.description = "";
        }
        var tmpGeo = _this.generateGeo(item.latlng);
        if (checkValue(tmpGeo)) {
          item.geo = tmpGeo;
          delete item.latlng;
        }
        // categoryCodes
        _this.generateCategoryCodes(item);
        delete item.goodFor;
        var segmentCodes = item.segmentCodes;
        if (checkValue(segmentCodes)) {
          segmentCodes = segmentCodes.filter(function (segmentCode) {
            return segmentCode !== "*nosegments";
          }).filter(function (segmentCode) {
            return segmentCode !== "_no_segments";
          });
          if (segmentCodes.length > 0) {
            segmentCodes.sort();
          }
          var goodFor_1 = [];
          segmentCodes.map(function (segmentCode, index) {
            goodFor_1.push({
              "segmentCode": segmentCode
            });
          });
          if (goodFor_1.length > 0) {
            item.goodFor = goodFor_1;
          }
        }
        if (checkValue(item.itemLink) && item.itemLink.length > 0) {
          var itemLinkContent = item.itemLink[0].properties;
          if (checkValue(itemLinkContent)) {
            item['targetUrl'] = _this.getGoGuidesUrl(itemLinkContent);
            delete item.itemLink;
          }
        }
        // this.ifNullDeleteThisNode(item, "nearbyProperties");
        // this.ifNullDeleteThisNode(item, "pdpLink");
        // this.ifNullDeleteThisNode(item, "openingHours");
        // this.ifNullDeleteThisNode(item, "location");
        // this.ifNullDeleteThisNode(item, "phoneNumber");
        // this.ifNullDeleteThisNode(item, "price");
        tmpItemBucket.push(item);
      });
    }
    if (tmpItemBucket.length > 0) {
      return tmpItemBucket;
    } else {
      return undefined;
    }
  };
  GoGuidesStore.prototype.fetchHero = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpH1 = goGuidesFetchedData.data.body.h1;
      var tmpH2 = goGuidesFetchedData.data.body.h2;
      var tmpImage = goGuidesFetchedData.data.body.image;
      var tmpPageType = goGuidesFetchedData.data.body['@type'];
      //process cms data
      //assign value
      var h1 = tmpH1;
      var h2 = tmpH2;
      var image = tmpImage;
      var pageType = tmpPageType;
      var pageCategory = void 0;
      var tmpPageCategory = goGuidesFetchedData.data.body.categoryLinks;
      if (checkValue(tmpPageCategory) && tmpPageCategory.length > 0) {
        var adjustedData = {
          categoryLinks: tmpPageCategory.map(function (item) {
            var name = item.name,
              rest = __rest(item, ["name"]);
            return __assign(__assign({}, rest), {
              segmentCode: name.toLowerCase().replaceAll(" ", "_")
            });
          })
        };
        pageCategory = adjustedData.categoryLinks;
      }
      //prepare prop
      result = {
        h1: h1,
        h2: h2,
        image: image,
        pageType: pageType,
        pageCategory: pageCategory
      };
    }
    return result;
  };
  GoGuidesStore.prototype.fetchGaiaIdAndDestinationName = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body.gaiaId) && checkValue(goGuidesFetchedData.data.body.destinationName)) {
      return {
        gaiaId: goGuidesFetchedData.data.body.gaiaId,
        destinationName: goGuidesFetchedData.data.body.destinationName,
        hideWhereToStay: goGuidesFetchedData.data.body.hideWhereToStay
      };
    }
  };
  GoGuidesStore.prototype.fetchBreadcrumb = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result = [];
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var breadcrumb = goGuidesFetchedData.data.body.breadcrumb;
      //process cms data
      //assign value
      if (checkValue(breadcrumb) && breadcrumb.length > 0) {
        //prepare prop
        result = breadcrumb;
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchNavigation = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpMenu = goGuidesFetchedData.data.body.menu;
      //process cms data
      //assign value
      if (checkValue(tmpMenu) && Object.keys(tmpMenu).length > 0) {
        //prepare prop
        result = tmpMenu;
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchIntrotext = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpIntroText = goGuidesFetchedData.data.body.introText;
      var tmpPageType = goGuidesFetchedData.data.body['@type'];
      //process cms data
      //assign value
      var introText = tmpIntroText;
      var pageType = tmpPageType;
      if (checkValue(introText)) {
        //prepare prop
        result = {
          introText: introText,
          pageType: pageType
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchTopDestinations = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpTopDestinations = goGuidesFetchedData.data.body.topDestinations;
      var tmpPageType = goGuidesFetchedData.data.body['@type'];
      var tmpDestinationType = goGuidesFetchedData.data.body.destinationType;
      var tmpDestinationName = goGuidesFetchedData.data.body.destinationName;
      //process cms data
      //assign value
      var items = tmpTopDestinations;
      var pageType = tmpPageType;
      var destinationType = tmpDestinationType;
      var destinationName = tmpDestinationName;
      if (checkValue(items) && items.length > 0) {
        //prepare prop
        result = {
          items: items,
          pageType: pageType,
          destinationType: destinationType,
          destinationName: destinationName
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchFeaturedStories = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpFeaturedStories = goGuidesFetchedData.data.body.items;
      var tmpPageType = goGuidesFetchedData.data.body['@type'];
      var tmpDestinationType = goGuidesFetchedData.data.body.destinationType;
      var tmpDestinationName = goGuidesFetchedData.data.body.destinationName;
      var tmpCategoryCode = goGuidesFetchedData.data.body.categoryCode;
      //process cms data
      //assign value
      var items = tmpFeaturedStories;
      var pageType = tmpPageType;
      var destinationType = tmpDestinationType;
      var destinationName = tmpDestinationName;
      var categoryCode = tmpCategoryCode;
      if (checkValue(items) && items.length > 0) {
        //prepare prop
        result = {
          items: items,
          pageType: pageType,
          destinationType: destinationType,
          destinationName: destinationName,
          categoryCode: categoryCode
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchKeepExploring = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    var destinationList = null;
    var worldLevel = false;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpDestinationList = goGuidesFetchedData.data.body.destinationList;
      var tmpDestinationId = goGuidesFetchedData.data.body.destinationId;
      //process cms data
      //assign value
      if (checkValue(tmpDestinationList) && Object.keys(tmpDestinationList).length > 0) {
        destinationList = tmpDestinationList;
        if (!checkValue(tmpDestinationId)) {
          worldLevel = true;
        }
      }
      //prepare prop
      result = {
        destinationList: destinationList,
        worldLevel: worldLevel
      };
    }
    return result;
  };
  GoGuidesStore.prototype.fetchOtherCategories = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpDestinationName = goGuidesFetchedData.data.body.destinationName;
      var tmpOtherCategories = goGuidesFetchedData.data.body.otherCategories;
      //process cms data
      //assign value
      var destinationName = tmpDestinationName;
      var categoriesData = tmpOtherCategories;
      if (checkValue(categoriesData) && checkValue(categoriesData.category) && categoriesData.category.length > 0) {
        //prepare prop
        result = {
          destinationName: destinationName,
          categoriesData: categoriesData
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchContributorsStories = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpContributorsStories = goGuidesFetchedData.data.body.contributorsStories;
      //process cms data
      //assign value
      var storiesData = tmpContributorsStories;
      if (checkValue(storiesData) && checkValue(storiesData.storiesList) && storiesData.storiesList.length > 0) {
        //prepare prop
        result = {
          storiesData: storiesData
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchAlsoPopular = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpParentDestinationName = goGuidesFetchedData.data.body.alsoPopularDestinationName;
      var tmpAlsoPopular = goGuidesFetchedData.data.body.alsoPopular;
      //process cms data
      //assign value
      var parentDestinationName = tmpParentDestinationName;
      var destinationData = tmpAlsoPopular;
      if (checkValue(destinationData) && destinationData.length > 0) {
        //prepare prop
        result = {
          parentDestinationName: parentDestinationName,
          destinationData: destinationData
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchOtherNeighbourhoods = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpOtherNeighbourhoods = goGuidesFetchedData.data.body.otherNeighbourhoods2;
      //process cms data
      //assign value
      var neighbourhoodsData = tmpOtherNeighbourhoods;
      if (checkValue(neighbourhoodsData) && checkValue(neighbourhoodsData.neighbourhoodsList) && neighbourhoodsData.neighbourhoodsList.length > 0) {
        //prepare prop
        result = {
          neighbourhoodsData: neighbourhoodsData
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchFreeText = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpFreeText = goGuidesFetchedData.data.body.freeText;
      //process cms data
      //assign value
      var freeText = tmpFreeText;
      var destinationName = goGuidesFetchedData.data.body.destinationName;
      if (checkValue(freeText)) {
        //prepare prop
        result = {
          freeText: freeText,
          destinationName: destinationName
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchWhatsAround = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpWhatsAround = goGuidesFetchedData.data.body.whatsAround;
      var tmpDestinationName = goGuidesFetchedData.data.body.destinationName;
      var tmpTravelBlog = goGuidesFetchedData.data.body.travelBlog;
      //process cms data
      //assign value
      var storiesData = tmpWhatsAround;
      var destinationName = tmpDestinationName;
      var travelBlog = tmpTravelBlog;
      if (checkValue(storiesData) && storiesData.length > 0) {
        //prepare prop
        result = {
          storiesData: storiesData,
          destinationName: destinationName,
          travelBlog: travelBlog
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchCategoryHighLightStories = function (pageUrlData, category, maxStories) {
    var e_3, _a;
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var highLight = void 0;
      var tmpPageType = goGuidesFetchedData.data.body['@type'];
      var tmpDestinationName = goGuidesFetchedData.data.body.destinationName;
      if ('THINGS_TO_DO' == category) {
        if (goGuidesFetchedData.data.body.mainHighlight && goGuidesFetchedData.data.body.mainHighlight.length > 0) {
          highLight = goGuidesFetchedData.data.body.mainHighlight[0];
          highLight.categoryCode = 'THINGS_TO_DO';
        }
      } else {
        if (goGuidesFetchedData.data.body.categoryHighlight && goGuidesFetchedData.data.body.categoryHighlight.length > 0) {
          try {
            for (var _b = __values(goGuidesFetchedData.data.body.categoryHighlight), _c = _b.next(); !_c.done; _c = _b.next()) {
              var tmpCategory = _c.value;
              if (tmpCategory.categoryCode == category) {
                highLight = tmpCategory;
              }
            }
          } catch (e_3_1) {
            e_3 = {
              error: e_3_1
            };
          } finally {
            try {
              if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            } finally {
              if (e_3) throw e_3.error;
            }
          }
        }
      }
      //process cms data
      //assign value
      var categoryData = void 0;
      var tmpCategoryHighLightStories = void 0;
      if (highLight && highLight.sellAllLink) {
        var adjustedData = __assign(__assign({}, highLight), {
          seeAllLink: highLight.sellAllLink
        });
        delete adjustedData.sellAllLink;
        tmpCategoryHighLightStories = adjustedData;
        categoryData = tmpCategoryHighLightStories;
      } else {
        categoryData = highLight;
      }
      if (checkValue(categoryData) && Object.keys(categoryData).length > 0) {
        var pageType = tmpPageType;
        var destinationName = tmpDestinationName;
        //prepare prop
        result = {
          categoryData: categoryData,
          pageType: pageType,
          destinationName: destinationName
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchWriter = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpWriter = goGuidesFetchedData.data.body.writer;
      //process cms data
      //assign value
      var writer = tmpWriter;
      if (checkValue(writer)) {
        //prepare prop
        result = {
          writer: writer
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchRelatedStories = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpRelatedStories = goGuidesFetchedData.data.body.relatedStories;
      var tmpListicleItem = goGuidesFetchedData.data.body.items;
      //process cms data
      //assign value
      var showSeeAlso = false;
      if (checkValue(tmpListicleItem) && tmpListicleItem.length > 3) {
        showSeeAlso = true;
      }
      if (checkValue(tmpRelatedStories) && tmpRelatedStories.length > 0) {
        var relatedStories = tmpRelatedStories;
        //prepare prop
        result = {
          relatedStories: relatedStories,
          showSeeAlso: showSeeAlso
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchListicleItems = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpItems = goGuidesFetchedData.data.body.items;
      var tmpFeatures = goGuidesFetchedData.data.body.features;
      //process cms data
      //assign value
      if (checkValue(tmpItems) && tmpItems.length > 0) {
        var adjustedData = {
          items: tmpItems.map(function (item) {
            var location = item.location,
              phoneNumber = item.phoneNumber,
              openingHours = item.openingHours,
              price = item.price,
              geo = item.geo,
              nearbyProperties = item.nearbyProperties,
              pdpLink = item.pdpLink,
              rest = __rest(item, ["location", "phoneNumber", "openingHours", "price", "geo", "nearbyProperties", "pdpLink"]);
            var tmpNearbyProperties;
            var tmpPdpLink;
            var tmpInfo = {};
            if (checkValue(location)) {
              tmpInfo['location'] = location;
            }
            if (checkValue(phoneNumber)) {
              tmpInfo['phoneNumber'] = phoneNumber;
            }
            if (checkValue(openingHours)) {
              tmpInfo['openingHours'] = openingHours;
            }
            if (checkValue(price)) {
              tmpInfo['price'] = price;
            }
            if (checkValue(geo)) {
              tmpInfo['geo'] = geo;
            }
            if (checkValue(nearbyProperties)) {
              tmpNearbyProperties = {
                targetUrl: nearbyProperties
              };
            }
            if (checkValue(pdpLink)) {
              tmpPdpLink = {
                targetUrl: pdpLink
              };
            }
            return __assign(__assign(__assign(__assign({}, rest), tmpInfo && {
              info: tmpInfo
            }), tmpNearbyProperties && {
              nearbyProperties: tmpNearbyProperties
            }), tmpPdpLink && {
              pdpLink: tmpPdpLink
            });
          })
        };
        var items = adjustedData.items;
        var features = tmpFeatures;
        //prepare prop
        result = {
          features: features,
          items: items
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchLocationInfo = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpTitle = goGuidesFetchedData.data.body.h1;
      var tmpInfo = goGuidesFetchedData.data.body.info;
      //process cms data
      //assign value
      if (checkValue(tmpTitle) && checkValue(tmpInfo)) {
        tmpInfo.title = tmpTitle;
        var info = tmpInfo;
        //prepare prop
        result = {
          info: info
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.fetchLongContents = function (pageUrlData) {
    var goGuidesFetchedData = this.jsonData;
    var result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      var tmpContents = goGuidesFetchedData.data.body.content;
      //process cms data
      //assign value
      if (checkValue(tmpContents) && tmpContents.length > 0) {
        var contents = tmpContents;
        //prepare prop
        result = {
          contents: contents
        };
      }
    }
    return result;
  };
  GoGuidesStore.prototype.generateImageUrl = function (imageUrl) {
    if ("/" == imageUrl[0]) {
      return this.imageDomain + imageUrl;
    } else {
      return imageUrl;
    }
  };
  __decorate([observable], GoGuidesStore.prototype, "isRouteChecked", void 0);
  __decorate([observable], GoGuidesStore.prototype, "pageUrlData", void 0);
  __decorate([observable], GoGuidesStore.prototype, "jsonData", void 0);
  __decorate([observable], GoGuidesStore.prototype, "isFallbackLanguage", void 0);
  __decorate([observable], GoGuidesStore.prototype, "imageDomain", void 0);
  __decorate([observable], GoGuidesStore.prototype, "canonicalUrlToReplace", void 0);
  __decorate([action], GoGuidesStore.prototype, "setCanonicalUrlToReplace", null);
  __decorate([action], GoGuidesStore.prototype, "setIsFallbackLanguage", null);
  __decorate([action], GoGuidesStore.prototype, "setIsRouteChecked", null);
  __decorate([action], GoGuidesStore.prototype, "setPageUrlData", null);
  __decorate([action], GoGuidesStore.prototype, "setJsonData", null);
  __decorate([action], GoGuidesStore.prototype, "fetchPage", null);
  __decorate([action], GoGuidesStore.prototype, "fetchPageData", null);
  __decorate([action], GoGuidesStore.prototype, "fetchHero", null);
  __decorate([action], GoGuidesStore.prototype, "fetchGaiaIdAndDestinationName", null);
  __decorate([action], GoGuidesStore.prototype, "fetchBreadcrumb", null);
  __decorate([action], GoGuidesStore.prototype, "fetchNavigation", null);
  __decorate([action], GoGuidesStore.prototype, "fetchIntrotext", null);
  __decorate([action], GoGuidesStore.prototype, "fetchTopDestinations", null);
  __decorate([action], GoGuidesStore.prototype, "fetchFeaturedStories", null);
  __decorate([action], GoGuidesStore.prototype, "fetchKeepExploring", null);
  __decorate([action], GoGuidesStore.prototype, "fetchOtherCategories", null);
  __decorate([action], GoGuidesStore.prototype, "fetchContributorsStories", null);
  __decorate([action], GoGuidesStore.prototype, "fetchAlsoPopular", null);
  __decorate([action], GoGuidesStore.prototype, "fetchOtherNeighbourhoods", null);
  __decorate([action], GoGuidesStore.prototype, "fetchFreeText", null);
  __decorate([action], GoGuidesStore.prototype, "fetchWhatsAround", null);
  __decorate([action], GoGuidesStore.prototype, "fetchCategoryHighLightStories", null);
  __decorate([action], GoGuidesStore.prototype, "fetchWriter", null);
  __decorate([action], GoGuidesStore.prototype, "fetchRelatedStories", null);
  __decorate([action], GoGuidesStore.prototype, "fetchListicleItems", null);
  __decorate([action], GoGuidesStore.prototype, "fetchLocationInfo", null);
  __decorate([action], GoGuidesStore.prototype, "fetchLongContents", null);
  return GoGuidesStore;
}(Store);