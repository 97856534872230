var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
// import { DateInput } from 'src/common/__generated__/api/types';
// import { } from 'src/com'
import { useState, useLayoutEffect } from 'react';
// import { utcToZonedTime } from 'date-fns-tz';
export var brandcId = function (templateType, campaignUrl) {
  var brandcIdVal = '';
  switch (templateType === null || templateType === void 0 ? void 0 : templateType.toLocaleLowerCase()) {
    case 'spotlight':
      {
        brandcIdVal = 'BRAND.MESO.MEDIASTUDIO.SPOTLIGHT';
        break;
      }
    default:
      {
        if (checkValue(campaignUrl)) {
          brandcIdVal = 'BRAND.MESO.MEDIASTUDIO.ACCELERATE';
        }
        break;
      }
  }
  return brandcIdVal;
};
var brands = ['expedia', 'hotels', 'ebookers', 'orbitz', 'cheaptickets', 'lastminute', 'mrjet', 'travelocity', 'wotif', 'vrbo'];
var isExpediaBrand = function (link) {
  try {
    var url_1 = new URL(link);
    return brands === null || brands === void 0 ? void 0 : brands.some(function (b) {
      var _a;
      return ((_a = url_1 === null || url_1 === void 0 ? void 0 : url_1.host) === null || _a === void 0 ? void 0 : _a.indexOf(b)) > -1;
    });
  } catch (e) {
    return (link === null || link === void 0 ? void 0 : link.startsWith('/')) ? true : false;
  }
};
export var getTranslateLoc = function (feed, name, defalutValue) {
  return checkValue(feed) && checkValue(feed[name]) ? feed[name] : defalutValue;
};
export var appendMcicid = function (link, templateType, campaignUrl, mainUrl, referrerId) {
  if (referrerId === void 0) {
    referrerId = '';
  }
  if (link === '' || link.indexOf('mcicid') > -1) return link;
  if (templateType === 'concierge' && !isExpediaBrand(link)) {
    return link;
  }
  var parameterPrefix = '?';
  var parameterMcicidName = 'mcicid';
  var paramterValuePrefix = 'cp';
  var parameterValueSeparater = '.';
  var paramTemplateType = templateType + parameterValueSeparater;
  // check the templatetype if it is not cplite then it is ( spotlight )
  // So update mcicd prefix
  // This apply only for the subpages
  if ((templateType === null || templateType === void 0 ? void 0 : templateType.toLocaleLowerCase()) !== 'cplite') {
    paramterValuePrefix = checkValue(mainUrl) ? "cp.".concat(mainUrl) : "cp.".concat(templateType);
    paramTemplateType = '';
  }
  var referrerIdParameterValue = referrerId != '' ? parameterValueSeparater + referrerId : '';
  if (link.indexOf('?') > -1) parameterPrefix = '&';
  // mcicid tracking
  var mcicidKey = parameterMcicidName + '=' + paramterValuePrefix + parameterValueSeparater + paramTemplateType + campaignUrl + referrerIdParameterValue;
  var tracking = parameterPrefix + mcicidKey;
  var url = link;
  var hashTag = '';
  // Check link has hashtag if so add hastag end of the url
  if (checkValue(link) && (link === null || link === void 0 ? void 0 : link.indexOf('#')) > -1) {
    var urlHashArray = link === null || link === void 0 ? void 0 : link.split('#');
    url = urlHashArray === null || urlHashArray === void 0 ? void 0 : urlHashArray[0];
    hashTag = checkValue(urlHashArray === null || urlHashArray === void 0 ? void 0 : urlHashArray[1]) ? '#' + (urlHashArray === null || urlHashArray === void 0 ? void 0 : urlHashArray[1]) : '';
  }
  // Check correct url
  url = (url === null || url === void 0 ? void 0 : url.startsWith('http')) || (url === null || url === void 0 ? void 0 : url.startsWith('/')) ? url : '//' + url;
  return url + tracking + hashTag;
};
export var mcicidFormatText = function (templateType, campaignUrl, mainUrl, referrerId) {
  if (referrerId === void 0) {
    referrerId = '';
  }
  var paramterValuePrefix = 'cp';
  var parameterValueSeparater = '.';
  var paramTemplateType = templateType + parameterValueSeparater;
  // check the templatetype if it is not cplite then it is ( spotlight )
  // So update mcicd prefix
  // This apply only for the subpages
  if ((templateType === null || templateType === void 0 ? void 0 : templateType.toLocaleLowerCase()) !== 'cplite') {
    paramterValuePrefix = checkValue(mainUrl) ? "cp.".concat(mainUrl) : "cp.".concat(templateType);
    paramTemplateType = '';
  }
  var referrerIdParameterValue = referrerId != '' ? parameterValueSeparater + referrerId : '';
  return paramterValuePrefix + parameterValueSeparater + paramTemplateType + campaignUrl + referrerIdParameterValue;
};
export var getDefaultValue = function (value, defaultValue) {
  if (defaultValue === void 0) {
    defaultValue = '';
  }
  return checkValue(value) ? value : defaultValue;
};
export var checkValue = function (value, isCheckLength) {
  if (isCheckLength === void 0) {
    isCheckLength = false;
  }
  var returnVal = false;
  if (value && value !== undefined && value !== null && value !== '') {
    if (isCheckLength) {
      if (value.length > 0) {
        returnVal = true;
      }
    } else {
      returnVal = true;
    }
  }
  return returnVal;
};
export var getCustomScript = function (customScript) {
  var e_1, _a;
  if (checkValue(customScript)) {
    var elCusScript = document.createElement('div');
    elCusScript.setAttribute('id', 'cusScript');
    elCusScript.style.cssText = 'position: absolute;width: 0;height: 0';
    document.getElementsByTagName('body')[0].appendChild(elCusScript);
    var cScript = document.getElementById('cusScript');
    if (checkValue(cScript)) {
      // eslint-disable-next-line no-useless-escape
      var scr = customScript.replace(/\\"/g, '"').replace(/<br\s*\/?>/gi, '');
      scr = scr.toLowerCase().indexOf('<script>') > -1 ? scr : "<script>".concat(scr, "</script>");
      cScript.innerHTML = scr;
      var scripts = cScript.getElementsByTagName('script');
      if (scripts.length > 0) {
        try {
          for (var scripts_1 = __values(scripts), scripts_1_1 = scripts_1.next(); !scripts_1_1.done; scripts_1_1 = scripts_1.next()) {
            var srcipt = scripts_1_1.value;
            eval(srcipt.innerText);
          }
        } catch (e_1_1) {
          e_1 = {
            error: e_1_1
          };
        } finally {
          try {
            if (scripts_1_1 && !scripts_1_1.done && (_a = scripts_1.return)) _a.call(scripts_1);
          } finally {
            if (e_1) throw e_1.error;
          }
        }
      }
    }
  }
};
export var LightenDarkenColor = function (hex, lum) {
  if (!hex) {
    return hex;
  }
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;
  // only black color
  hex = hex === '000000' ? '484848' : hex;
  var isLightOrDark = colorHelper.lightOrDark(hex);
  if (isLightOrDark === 'dark') {
    lum = Math.abs(lum);
  } else if (isLightOrDark === 'too-dark') {
    lum = 0.5;
  }
  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }
  return rgb;
};
export var colorHelper = {
  lightOrDark: function (color) {
    var r, g, b;
    if (color.match(/^rgb/)) {
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }
    var hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)); // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return 'light';
    } else if (hsp > 50) {
      return 'dark';
    } else {
      return 'too-dark';
    }
  }
};
// crop params remove from Image URL
export var removeImageURLCropParamsAll = function (imgUrl) {
  var img = imgUrl === null || imgUrl === void 0 ? void 0 : imgUrl.split('?');
  return checkValue(img) ? img[0] : '';
};
export var removeImageURLCropParams = function (imgUrl) {
  var _a;
  if (checkValue(imgUrl) && imgUrl.indexOf('?') > -1) {
    var tinyImg = imgUrl === null || imgUrl === void 0 ? void 0 : imgUrl.split('?'),
      removedCropTinyImg = (_a = tinyImg[1]) === null || _a === void 0 ? void 0 : _a.split('&').filter(function (params) {
        return !params.includes('crop');
      }),
      newHerotinyImage = tinyImg[0].length > 0 ? tinyImg[0] + '?crop=&anchor=center&mode=crop&' + (removedCropTinyImg === null || removedCropTinyImg === void 0 ? void 0 : removedCropTinyImg.join('&')) : '';
    return newHerotinyImage;
  } else {
    return imgUrl;
  }
};
export var getQueryParams = function (paramKey) {
  var _a;
  if (typeof window !== 'undefined') {
    var urlSearchParams = new URLSearchParams((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.search);
    var params = Object.fromEntries(urlSearchParams.entries());
    if (!checkValue(params)) {
      return '';
    }
    return params === null || params === void 0 ? void 0 : params[paramKey];
  } else {
    return '';
  }
};
export var getParameterByName = function (name) {
  var _a;
  if (typeof window !== 'undefined') {
    var url = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.href;
    if (url === '') {
      return '';
    } else {
      return getQueryParams(name);
    }
  } else {
    return '';
  }
};
export var getLink = function (url) {
  if (url === void 0) {
    url = '';
  }
  if (!checkValue(url === null || url === void 0 ? void 0 : url.trim())) {
    return url;
  }
  return (url === null || url === void 0 ? void 0 : url.toLowerCase().indexOf('http://')) > -1 || (url === null || url === void 0 ? void 0 : url.toLowerCase().indexOf('https://')) > -1 || (url === null || url === void 0 ? void 0 : url.startsWith('/')) ? url : "http://".concat(url);
};
var ItemKeyHelper = /** @class */function () {
  function ItemKeyHelper(baseKey) {
    this.itemIndex = 0;
    this.baseKey = baseKey;
  }
  ItemKeyHelper.prototype.next = function () {
    return "".concat(this.baseKey, "_").concat(this.itemIndex++);
  };
  return ItemKeyHelper;
}();
export { ItemKeyHelper };
export var capitalizeFirst = function (str) {
  var words = str.replaceAll('-', ' ').split(' ');
  words.map(function (word, index) {
    words[index] = word.charAt(0).toUpperCase() + word.slice(1);
  });
  return words.join(' ');
};
export var appendMcicidToCmsTypo = function (templateType, campaignUrl, mainUrl, referrerId) {
  if (referrerId === void 0) {
    referrerId = '';
  }
  if (document) {
    setTimeout(function () {
      var _a;
      var allCmsTypo = document.querySelectorAll('.cms-typo a');
      for (var i = 0; i < allCmsTypo.length; i++) {
        var aTag = allCmsTypo[i];
        var aTagHref = aTag.hasAttribute('href');
        var aTagHrefValue = aTag.getAttribute('href');
        if (aTagHref && ((_a = aTagHrefValue === null || aTagHrefValue === void 0 ? void 0 : aTagHrefValue.toLocaleLowerCase()) === null || _a === void 0 ? void 0 : _a.indexOf('mcicid')) == -1) {
          var aTagWithMcicid = appendMcicid(aTagHrefValue, templateType, campaignUrl, mainUrl, referrerId);
          aTag.href = aTagWithMcicid;
          var aTagTarget = aTag.getAttribute('target');
          if (!checkValue(aTagTarget)) {
            aTag.target = '_blank';
          }
        }
      }
    }, 1500);
  }
};
export var localization = {
  setLocale: function (locale) {
    return void 0;
  },
  formatDate: function (date, options) {
    if (options && date) {
      var option = options.skeleton || options.raw;
      var locale = options.locale || 'en';
      moment.locale(locale);
      switch (option) {
        case 'MMMEd':
          {
            return moment(date).format('ddd, MMM D');
          }
        case 'dddDMMM':
          {
            return moment(date).format('ddd, D MMM');
          }
        case 'yMMMM':
          {
            return moment(date).format('MMMM YYYY');
          }
        case 'd':
          {
            return moment(date).format('D');
          }
        case 'EEEEE':
          {
            return moment(date).format('ddd');
          }
        case 'yyyy-MM-dd':
          {
            return moment(date).format('YYYY-MM-DD');
          }
        case 'MM/DD/yyyy':
          {
            return moment(date).format('MM/DD/yyyy');
          }
        case 'MMMd':
          {
            return moment(date).format('MMM D');
          }
        case 'EEEE':
          {
            return moment(date).format('ddd');
          }
        case 'MMMM':
          {
            return moment(date).format('MMMM');
          }
        case 'yMMMMd':
          {
            return moment(date).format('MMMM D YYYY');
          }
        case 'yMMMd':
          {
            return moment(date).format('MMM D YYYY');
          }
        case 'dddMMMD':
          {
            return moment(date).format('ddd, MMM D');
          }
        default:
          {
            moment(date).format();
          }
      }
    }
    return date && moment(date).format();
  },
  formatDateString: function (dateString, options) {
    if (options && dateString) {
      var option = options.skeleton || options.raw;
      switch (option) {
        case 'MMMEd':
          {
            return moment(dateString).format('MMM D');
          }
        case 'yMMMM':
          {
            return moment(dateString).format('MMMM YYYY');
          }
        case 'yyyy-MM-dd':
          {
            return moment(dateString).format('YYYY-MM-DD');
          }
        case 'M':
          {
            return moment(dateString).format('M');
          }
        default:
          {
            return moment(dateString).format('MMM D');
          }
      }
    }
    return moment(dateString).format('MMM D');
  },
  formatText: function (key) {
    return 'Select Text';
  },
  getWeekData: function () {
    return {
      firstDay: 'sun'
    };
  },
  parseLocalDateString: function (dateString, options) {
    if (options && dateString) {
      return moment(dateString, 'YYYY-MM-DD').toDate();
    }
    return moment(dateString, 'YYYY-MM-DD').toDate();
  }
};
export var formatMonth = function (month, options) {
  if (options) {
    var option = options.skeleton || options.raw;
    var locale = options.locale || 'en';
    moment.locale(locale);
    switch (option) {
      case 'MMMM':
        {
          return moment().month(month).format('MMMM');
        }
      default:
        {
          return moment().month(month).format('M');
        }
    }
  }
};
export var getQueryVariable = function (queryString, variable) {
  var e_2, _a;
  var query = queryString.substring(1);
  var vars = query.split('&');
  if (checkValue(vars, true)) {
    try {
      for (var vars_1 = __values(vars), vars_1_1 = vars_1.next(); !vars_1_1.done; vars_1_1 = vars_1.next()) {
        var val = vars_1_1.value;
        var pair = val.split('=');
        if (pair[0] == variable) {
          return pair[1];
        }
      }
    } catch (e_2_1) {
      e_2 = {
        error: e_2_1
      };
    } finally {
      try {
        if (vars_1_1 && !vars_1_1.done && (_a = vars_1.return)) _a.call(vars_1);
      } finally {
        if (e_2) throw e_2.error;
      }
    }
  }
  return false;
};
export var defaultHeroImage = function () {
  return [{
    name: 'hero-desktop',
    url: 'https://mesocp.azureedge.net/media/kknnrovx/static-image.svg'
  }, {
    name: 'hero-tablet',
    url: 'https://mesocp.azureedge.net/media/kknnrovx/static-image.svg'
  }, {
    name: 'hero-mobile',
    url: 'https://mesocp.azureedge.net/media/kknnrovx/static-image.svg'
  }];
};
export var isWizardSupportedLocalization = function (local) {
  var wizardNotSupportedLocal = ['ar_AE', 'et_EE', 'hr_HR', 'is_IS', 'lt_LT', 'lv_LV', 'pl_PL', 'ru_RU', 'sk_SK', 'tr_TR', 'he_IL', 'iw_IL'];
  return !wizardNotSupportedLocal.includes(local);
};
export var instagramImageUrl = function (imgUrl) {
  var encodedImage = encodeURIComponent(imgUrl);
  return "https://proxy.elfsightcdn.com/?url=".concat(encodedImage);
};
export var getImage = function (images, imageType) {
  var image = '';
  if (checkValue(images, true)) {
    if (checkValue(imageType)) {
      var filteredImage = images.filter(function (img) {
        return img.name.toLocaleLowerCase().includes(imageType);
      });
      image = filteredImage.length > 0 ? filteredImage[0].url : '';
    } else {
      var filteredImage = images[0];
      image = filteredImage[0].url;
    }
  }
  return image;
};
export var getLocalStorageList = function (key) {
  return localStorage.getItem(key);
};
export var removeLocalStorageList = function (key) {
  localStorage.removeItem(key);
};
export var setQueryParamKeyValue = function (queryKeyValueParams) {
  if (typeof window !== 'undefined') {
    var windowLocation = window === null || window === void 0 ? void 0 : window.location;
    var locationSearch_1 = windowLocation.search;
    var adFilterSearch_1 = locationSearch_1;
    if (queryKeyValueParams && queryKeyValueParams.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      queryKeyValueParams.forEach(function (kv, i) {
        var queryParamKey = kv.queryParamKey,
          queryParamValue = kv.queryParamValue;
        var urlSearchParams = new URLSearchParams(locationSearch_1);
        var params = Object.fromEntries(urlSearchParams.entries());
        var paramsKeyValue = params[queryParamKey];
        if (checkValue(paramsKeyValue)) {
          if (params[queryParamKey] === '') {
            var regex1 = new RegExp([queryParamKey, '='].join(''), 'i'); //i mean case insensitive
            var regexReplace1 = adFilterSearch_1.replace(regex1, "".concat(queryParamKey, "=") + queryParamValue);
            adFilterSearch_1 = regexReplace1;
          } else {
            var regex2 = new RegExp(['(', queryParamKey, '=)[^&]+'].join(''), 'i');
            var regexReplace2 = adFilterSearch_1.replace(regex2, '$1' + queryParamValue);
            adFilterSearch_1 = regexReplace2;
          }
        } else {
          adFilterSearch_1 = adFilterSearch_1.indexOf('?') > -1 ? adFilterSearch_1 + "&".concat(queryParamKey, "=") + queryParamValue : adFilterSearch_1 + "?".concat(queryParamKey, "=") + queryParamValue;
        }
      });
    }
    return adFilterSearch_1;
  } else {
    return '';
  }
};
export var checkVrbo = function (siteId) {
  var vrboPos = ['9001001', '9003020', '9003013', '9006043', '9005044'];
  return vrboPos.includes(siteId);
};
export var dateHelper = {
  today: function () {
    return new Date();
  },
  addDate: function (dateNumber) {
    var today = dateHelper.today();
    var setDate = dateHelper.today();
    setDate.setDate(today.getDate() + dateNumber);
    return setDate;
  },
  setDate: function (date, days) {
    var dateToSetDays = new Date(date);
    dateToSetDays.setDate(date.getDate() + days);
    return dateToSetDays;
  },
  dateFormat: function (checkInDate, fallbackDate) {
    try {
      var dateFormatted = checkValue(checkInDate) ? new Date(checkInDate) : fallbackDate;
      // Check date is future
      if (!dateHelper.isDateInFuture(dateFormatted)) {
        dateFormatted = fallbackDate;
      }
      return dateFormatted;
    } catch (e) {
      return null;
    }
  },
  dateInputFormat: function (checkInDate, fallbackDate) {
    try {
      var dateFormatted = dateHelper.dateFormat(checkInDate, fallbackDate);
      return {
        day: dateFormatted.getDate(),
        month: dateFormatted.getMonth() + 1,
        year: dateFormatted.getFullYear()
      };
    } catch (e) {
      return null;
    }
  },
  getDateWithInputFormat: function (date) {
    try {
      return {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear()
      };
    } catch (e) {
      return null;
    }
  },
  validDate: function (checkInDate, today, addDate) {
    if (addDate === void 0) {
      addDate = 21;
    }
    addDate = addDate < 21 ? 21 : addDate;
    var fallbackDate = dateHelper.setDate(today, addDate);
    try {
      if (checkValue(checkInDate)) {
        var newCheckInDate = new Date(checkInDate);
        fallbackDate = dateHelper.diffDays(newCheckInDate, today) < 21 ? fallbackDate : newCheckInDate;
      }
      return fallbackDate;
    } catch (e) {
      return fallbackDate;
    }
  },
  isDateInFuture: function (date) {
    var today = new Date();
    return date > today;
  },
  isAfterDate: function (date1, date2) {
    return new Date(date1) < new Date(date2);
  },
  diffDays: function (date1, date2) {
    return Math.ceil((date1 - date2) / (1000 * 60 * 60 * 24));
  },
  diffHours: function (date1, date2) {
    return Math.ceil((date1 - date2) / (1000 * 60 * 60));
  },
  /**
   *
   * @param fixedDate - CMS setting fixed date check in / check out
   * @param dateString - CMS setting check in / check out it set from 1 weeks from today
   * @returns formatted date string
   */
  getFixedDate: function (fixedDate, dateString) {
    var today = new Date();
    var futureDate = '';
    if (checkValue(dateString)) {
      var noofweeks = checkValue(dateString) ? parseInt(dateString.replace(/\D/g, '')) : 0;
      futureDate = new Date(today.getTime() + noofweeks * 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US').concat(' 12:00:00 AM');
    }
    return checkValue(fixedDate) ? fixedDate : futureDate;
  },
  getDateFromWeeksFromToday: function (weeksFromToday) {
    var _a;
    if (checkValue(weeksFromToday)) {
      var getWeeksTextFromToday = (_a = weeksFromToday === null || weeksFromToday === void 0 ? void 0 : weeksFromToday.replace(' Weeks from Today', '')) === null || _a === void 0 ? void 0 : _a.trim();
      if (checkValue(getWeeksTextFromToday)) {
        var getDateToAdded = Math.imul(getWeeksTextFromToday, 7);
        return dateHelper.addDate(Number(getDateToAdded));
      }
    }
    return null;
  }
};
export var filterDataFromList = function (list, filterKey) {
  var filteredItemIndex = list.findIndex(function (el) {
    return checkValue(el[filterKey]);
  });
  if (filteredItemIndex === -1) return list;
  var dataArr = __spreadArray([], __read(list), false);
  dataArr.splice(0, 1);
  return dataArr;
};
export var useWindowSize = function () {
  var _a = __read(useState([0, 0]), 2),
    size = _a[0],
    setSize = _a[1];
  useLayoutEffect(function () {
    var updateSize = function () {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return function () {
      return window.removeEventListener('resize', updateSize);
    };
  }, []);
  return size;
};
export var makeHexCode = function (code) {
  if (!checkValue(code)) return '';
  if (code.includes('#')) return code;
  return "#".concat(code);
};
// export const dealHelper = {
//   getCheckInCheckOutDates: (checkInDate: string, checkOutDate: string) => {
//     const checkInBy14Day = dateHelper.addDate(14);
//     const checkOutBy16Day = dateHelper.addDate(16);
//     const checkInDateFormatted = dateHelper.dateFormat(checkInDate, checkInBy14Day);
//     let checkOutDateFormatted = dateHelper.dateFormat(checkOutDate, checkOutBy16Day);
//     // CheckOut Date is future date than the checkindate
//     if (!dateHelper.isAfterDate(new Date(checkInDateFormatted), new Date(checkOutDateFormatted))) {
//       const newDateForCheckOut = checkInDateFormatted;
//       checkOutDateFormatted = dateHelper.setDate(newDateForCheckOut, 2);
//     }
//     const checkInDateFormattedInputFormat: DateInput = dateHelper.getDateWithInputFormat(checkInDateFormatted);
//     const checkOutDateFormattedInputFormat: DateInput = dateHelper.getDateWithInputFormat(checkOutDateFormatted);
//     return { checkInDateFormattedInputFormat, checkOutDateFormattedInputFormat };
//   },
// };
export var getConciergeCategoryLogo = function (categoryName) {
  if (!checkValue(categoryName)) return '';
  var cardCategoryLogoImages = {
    'adventure-and-outdoors': 'https://mesocp.azureedge.net/media/helata2w/adventure-and-outdoors.svg',
    'arts-and-heritage': 'https://mesocp.azureedge.net/media/zhhd2ntx/arts-and-heritage.svg',
    'attractions-and-sightseeing': 'https://mesocp.azureedge.net/media/diapjcnv/attractions-and-sightseeing.svg',
    'entertainment-and-events': 'https://mesocp.azureedge.net/media/fjnlzbc0/entertainment-and-events.svg',
    'food-drinks-and-dining': 'https://mesocp.azureedge.net/media/ixyl5lro/food-drinks-and-dining.svg',
    guests: 'https://mesocp.azureedge.net/media/o2uckana/guests.svg',
    history: 'https://mesocp.azureedge.net/media/pjvjtby5/history.svg',
    holiday: 'https://mesocp.azureedge.net/media/5tmpmjqo/holiday.svg',
    inclusive: 'https://mesocp.azureedge.net/media/b1nmzn1b/inclusive.svg',
    'length-of-stay': 'https://mesocp.azureedge.net/media/xy3o4mlf/length-of-stay.svg',
    location: 'https://mesocp.azureedge.net/media/tr1a4ezf/location.svg',
    movie: 'https://mesocp.azureedge.net/media/jl3jm2ui/movie.svg',
    'muslim-friendly': 'https://mesocp.azureedge.net/media/ipnao5tq/muslim-friendly.svg',
    'nature-and-wildlife': 'https://mesocp.azureedge.net/media/w4eh5h4k/nature-and-wildlife.svg',
    relaxation: 'https://mesocp.azureedge.net/media/av2p512l/relaxation.svg',
    wellness: 'https://mesocp.azureedge.net/media/hqmdrlnw/wellness.svg'
  };
  return cardCategoryLogoImages[categoryName.toLowerCase()];
};
export var cookieHelper = {
  setCookie: function (name, value, days) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  },
  getCookie: function (name) {
    var e_3, _a;
    var _b;
    if (!checkValue(document)) {
      return null;
    }
    var nameEQ = name + '=';
    var ca = (_b = document === null || document === void 0 ? void 0 : document.cookie) === null || _b === void 0 ? void 0 : _b.split(';');
    try {
      for (var ca_1 = __values(ca), ca_1_1 = ca_1.next(); !ca_1_1.done; ca_1_1 = ca_1.next()) {
        var element = ca_1_1.value;
        var c = element;
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
    } catch (e_3_1) {
      e_3 = {
        error: e_3_1
      };
    } finally {
      try {
        if (ca_1_1 && !ca_1_1.done && (_a = ca_1.return)) _a.call(ca_1);
      } finally {
        if (e_3) throw e_3.error;
      }
    }
    return null;
  },
  deleteCookie: function (name) {
    if (checkValue(document)) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }
};
export var getCurlyQuotes = function (copy) {
  if (!checkValue(copy)) return '';
  var newCopy = copy;
  var occurance = 1;
  newCopy = __spreadArray([], __read(newCopy), false).map(function (char) {
    if (char === '"' || char === '“') {
      if (occurance % 2 === 1) char = '<span class="replaced-qoutation-mark double-qoutes reverse">,,</span>';else char = '<span class="replaced-qoutation-mark double-qoutes">,,</span>';
      occurance += 1;
    }
    return char;
  }).join('');
  newCopy = newCopy.replaceAll('’', '<span class=replaced-qoutation-mark>,</span>');
  newCopy = newCopy.replaceAll("'", '<span class=replaced-qoutation-mark>,</span>');
  return newCopy;
};
export var getRandomItemFromList = function (list) {
  if (!checkValue(list, true)) return null;
  return list[Math.floor(Math.random() * list.length)];
};
export var toTitleCase = function (str, replaceHyphen) {
  if (replaceHyphen === void 0) {
    replaceHyphen = false;
  }
  if (replaceHyphen) str = str.replaceAll('-', ' ');
  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};
export var isDateExpired = function (currentdate, expirydate, setTime2Zero) {
  if (setTime2Zero === void 0) {
    setTime2Zero = false;
  }
  var expiryDate = checkValue(expirydate) ? expirydate : false;
  var timeZone = "America/New_York";
  if (expiryDate) {
    var utcExpiryDateString = expiryDate.toLocaleString('en-US', {
      timeZone: timeZone
    });
    var utcCurrentDateString = currentdate.toLocaleString('en-US', {
      timeZone: timeZone
    });
    var d2 = new Date(utcExpiryDateString);
    var d1 = new Date(utcCurrentDateString);
    if (setTime2Zero) {
      d1.setHours(0, 0, 0, 0);
      d2.setHours(0, 0, 0, 0);
    }
    return d1 <= d2;
  } else {
    return true;
  }
};
export var quoteAlterations = {
  isQuotesAvailable: function (quoteText) {
    return quoteText.slice(0, 1) === '"' || quoteText.slice(-1) === '"' || quoteText.slice(0, 1) === '“' || quoteText.slice(-1) === '”';
  },
  addQuotes: function (quoteText) {
    var string = quoteText;
    if (quoteText.slice(0, 1) === '"' || quoteText.slice(0, 1) === '“') {
      string = string.replace(/^.{1}/g, '');
    }
    if (quoteText.slice(-1) === '"' || quoteText.slice(-1) === '”') {
      string = string.replace(/.{1}$/g, '');
    }
    return "\n      <span class=\"quote-one\">,,</span>\n      ".concat(string, "\n      <span class=\"quote-two\">,,</span>\n    ");
  },
  makeQuoteText: function (rawQuoteText) {
    var breakTagRegex = /(<|&lt;)br\s*\/*(>|&gt;)/g;
    var text = rawQuoteText;
    // remove <br /> tags before text
    var beforeTextIndex = text.indexOf('"') !== -1 ? text.indexOf('"') : text.indexOf('“');
    var beforeText = '';
    if (beforeTextIndex > 0) {
      beforeText = text.substring(0, beforeTextIndex);
      beforeText = beforeText.replace(breakTagRegex, '');
    }
    // remove <br /> tags after text
    var afterTextIndex = text.lastIndexOf('"') !== -1 ? text.lastIndexOf('"') : text.indexOf('”');
    var afterText = '';
    if (afterTextIndex > 0) {
      afterText = text.substring(afterTextIndex + 1, text.length);
      afterText = afterText.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, '');
    }
    if (afterTextIndex === -1 || beforeTextIndex === -1) return text;
    // join strings and remove whitespaces from the beginning and end of strings... including newlines.
    return "".concat(beforeText, " ").concat(text.substring(beforeTextIndex, afterTextIndex), " ").concat(afterText).trim();
  }
};
export var getUserTimeZone = function () {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
export var getDateStringToIsoFormat = function (dateString, eventT) {
  var _a = __read(dateString.split(' '), 3),
    datePart = _a[0],
    timePart = _a[1],
    ampm = _a[2];
  var _b = __read(datePart.split('/'), 3),
    month = _b[0],
    day = _b[1],
    year = _b[2];
  var _c = __read(timePart.split(':'), 2),
    hour = _c[0],
    minute = _c[1];
  var monthString = parseInt(month).toString().padStart(2, '0');
  var dayString = parseInt(day).toString().padStart(2, '0');
  var hourString = hour.toString().padStart(2, '0');
  if (ampm === 'AM' && hour === '12') {
    hourString = '00';
  } else if (ampm === 'PM' && hour !== '12') {
    hourString = (parseInt(hour) + 12).toString();
  }
  var minuteString = parseInt(minute).toString().padStart(2, '0');
  var timeZoneString = eventT === "EDT" ? "-04:00" : "-05:00";
  var dateTime = "".concat(year, "-").concat(monthString, "-").concat(dayString, "T").concat(hourString, ":").concat(minuteString, ":00").concat(timeZoneString);
  return dateTime;
};
// export const convertEventTime = (eventDate, eventT) => {
//   const userTimeZone = getUserTimeZone();
//   const eventDateTime = getDateStringToIsoFormat(eventDate , eventT);
//   const userZonedTime = utcToZonedTime(eventDateTime, userTimeZone);
//   return userZonedTime
// };
export var getUniqueListBy = function (arr, key) {
  return __spreadArray([], __read(new Map(arr.map(function (item) {
    return [item[key], item];
  })).values()), false);
};
export var breakWordwithSPChar = function (word, spchar) {
  if (spchar === void 0) {
    spchar = "&shy;";
  }
  var isWordConatainsHash = word.includes("#");
  var index = 8;
  if (isWordConatainsHash) {
    console.log(word.replace(/#/g, spchar), "word.replace(/#/g, spchar)", word);
    return word.replace(/#/g, spchar);
  } else if (!isWordConatainsHash && index >= 0 && index <= word.length) {
    var firstPart = word.slice(0, index);
    var secondPart = word.slice(index);
    return firstPart + spchar + secondPart;
  } else {
    return word;
  }
};