var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import { NOOP_LOGGER } from "bernie-logger";
import { checkValue } from "components/functions";
import { catHubData } from "components/mock-data/cathub";
import { dhubData } from "components/mock-data/dhub";
import { homeData } from "components/mock-data/home";
import { listicleData } from "components/mock-data/listicle";
import { longFormData } from "components/mock-data/longform";
import { config } from "config/config";
import fetch from "isomorphic-fetch";
import { domainDictionary } from "stores/helpers/data-maps";
var GG_CMS_API = config === null || config === void 0 ? void 0 : config.caps_api;
var GoGuidesSource = /** @class */function () {
  function GoGuidesSource(logger) {
    if (logger === void 0) {
      logger = NOOP_LOGGER;
    }
    this.logger = logger;
  }
  GoGuidesSource.prototype.extractPageUrl = function (pageUrlData) {
    var pageUrl = pageUrlData.url;
    var locale = pageUrlData.locale;
    var domainUrl = domainDictionary[locale];
    var domain = domainUrl.domain;
    var queryString = domainUrl.queryString;
    var umbracoLocale = domainUrl.umbracoLocale;
    return {
      domain: domain,
      pageUrl: pageUrl,
      queryString: queryString,
      locale: locale,
      umbracoLocale: umbracoLocale
    };
  };
  GoGuidesSource.prototype.fetchDDAPageDataOffline = function (pageUrl) {
    return __awaiter(this, void 0, void 0, function () {
      var json;
      return __generator(this, function (_a) {
        json = homeData[pageUrl];
        if (checkValue(json)) return [2 /*return*/, json];
        json = dhubData[pageUrl];
        if (checkValue(json)) return [2 /*return*/, json];
        json = catHubData[pageUrl];
        if (checkValue(json)) return [2 /*return*/, json];
        json = listicleData[pageUrl];
        if (checkValue(json)) return [2 /*return*/, json];
        json = longFormData[pageUrl];
        if (checkValue(json)) return [2 /*return*/, json];
        return [2 /*return*/, null];
      });
    });
  };
  GoGuidesSource.prototype.fetchCMSPageDataByPageUrl = function (pageUrlData) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, domain, pageUrl, queryString, umbracoLocale, source, goGuidesFetchedData, url, apiUrl, extraParam, checkEnv;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _a = this.extractPageUrl(pageUrlData), domain = _a.domain, pageUrl = _a.pageUrl, queryString = _a.queryString, umbracoLocale = _a.umbracoLocale;
            source = pageUrlData.source;
            if (!("dda" == source)) return [3 /*break*/, 3];
            if (!checkValue(domain)) return [3 /*break*/, 2];
            return [4 /*yield*/, this.fetchDDAPageDataOnline(domain, pageUrl, queryString).then(function (data) {
              return data;
            })];
          case 1:
            goGuidesFetchedData = _b.sent();
            _b.label = 2;
          case 2:
            return [3 /*break*/, 7];
          case 3:
            if (!("json" == source)) return [3 /*break*/, 5];
            return [4 /*yield*/, this.fetchDDAPageDataOffline(pageUrl)];
          case 4:
            goGuidesFetchedData = _b.sent();
            return [3 /*break*/, 7];
          case 5:
            url = pageUrlData.url;
            apiUrl = void 0;
            if ("/go" == url || "/go/" == url) {
              apiUrl = "/goguides/home";
            } else {
              apiUrl = url.replace("/go/", "/goguides/");
            }
            extraParam = "";
            checkEnv = "";
            try {
              if (typeof process != 'undefined' && typeof process.env != 'undefined' && typeof process.env.EXPEDIA_ENVIRONMENT != 'undefined') {
                checkEnv = process.env.EXPEDIA_ENVIRONMENT;
              }
            } catch (e) {
              console.log(e);
            }
            if ("test" == checkEnv) {
              // extraParam = "&ispreview=1"
            }
            if ("false" == pageUrlData.skipCache) {
              extraParam = "&ispreview=1";
            }
            return [4 /*yield*/, this.fetchUmbracoCMSPageData("https://uk.hotels.com/capsapi", apiUrl, "locale=" + umbracoLocale + extraParam).then(function (data) {
              return data;
            })];
          case 6:
            goGuidesFetchedData = _b.sent();
            _b.label = 7;
          case 7:
            return [2 /*return*/, goGuidesFetchedData];
        }
      });
    });
  };
  GoGuidesSource.prototype.fetchDDAPageDataOnline = function (domain, pageUrl, queryString) {
    var _a, _b, _c, _d, _e;
    return __awaiter(this, void 0, void 0, function () {
      var url, result, data;
      return __generator(this, function (_f) {
        switch (_f.label) {
          case 0:
            url = domain + pageUrl + '?format=json&' + queryString;
            console.log("ggcontent: fetch content from " + url);
            return [4 /*yield*/, fetch(url, {
              mode: 'no-cors',
              method: 'GET'
            }).then(function (res) {
              return res.json().then(function (response) {
                return response;
              });
            }).catch(function (err) {
              return {
                error: err
              };
            })];
          case 1:
            result = _f.sent();
            if (checkValue(result) && checkValue((_c = (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.page) === null || _b === void 0 ? void 0 : _b.html) === null || _c === void 0 ? void 0 : _c.locale) && checkValue((_e = (_d = result.data) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e.locale)) {
              data = result;
            }
            return [2 /*return*/, data];
        }
      });
    });
  };
  GoGuidesSource.prototype.fetchUmbracoCMSPageData = function (domain, pageUrl, queryString) {
    return __awaiter(this, void 0, void 0, function () {
      var url, result, data;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            url = domain + pageUrl.toLowerCase() + '?' + queryString;
            console.log("fetch content from " + url);
            return [4 /*yield*/, fetch(url, {
              mode: 'no-cors',
              method: 'GET'
            }).then(function (res) {
              return res.json().then(function (response) {
                return response;
              });
            }).catch(function (err) {
              return {
                error: err
              };
            })];
          case 1:
            result = _a.sent();
            if (checkValue(result) && checkValue(result.id) && checkValue(result.name) && checkValue(result.contentType)) {
              data = result;
            }
            return [2 /*return*/, data];
        }
      });
    });
  };
  GoGuidesSource.prototype.fetchCMSPageData = function (templateName, feedName, pagelang, siteId, isCacheFeed) {
    return __awaiter(this, void 0, void 0, function () {
      var isPreview, campFeedName, url;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            isPreview = '';
            if (!isCacheFeed) {
              isPreview = '?ispreview=1';
            }
            campFeedName = feedName !== '' ? feedName + '/' : '';
            url = GG_CMS_API + templateName + '/' + campFeedName + pagelang + '/' + siteId + isPreview;
            return [4 /*yield*/, fetch(url, {
              method: 'GET'
            }).then(function (res) {
              return res.json().then(function (response) {
                console.log("responseresponseresponse " + JSON.stringify(response));
                return response;
              });
            }).catch(function (err) {
              return {
                error: err
              };
            })];
          case 1:
            //const url = 'http://localhost:3000/capsapi/template-cplite/heritage/en_US/1?ispriview=1';
            return [2 /*return*/, _a.sent()];
        }
      });
    });
  };
  return GoGuidesSource;
}();
export { GoGuidesSource };